<template>
  <div class="menu-container">
    <div style="height:36px;line-height:36px">
      <span class="w150">创意样式</span>
      <span style="font-weight:bold">大图式卡片</span>
    </div>
    <div class="image-part">
      <div class="ad-image">
        <div class="ad-image-container">
          <div>
            <div
              style="display:flex;justify-content:space-between;align-items:center;width:100%"
            ></div>
            <div class="image-list">
              <div>
                <span style="color:#9C9C9C">广告位图片</span>
                <div class="ad-upload-image">
                  <div
                    class="upload-image"
                    v-for="(item, index) in itemObj.imgUrl"
                    :key="index"
                  >
                    <img :src="item" alt="" />
                    <div class="mask">
                      <i class="el-icon-delete" @click="delAdImage(index)"></i>
                    </div>
                  </div>
                  <div
                    tabindex="0"
                    class="custom-upload"
                    @click="uploadAdImage"
                  >
                    <i class="iconfont icon-xinzeng"></i>
                  </div>
                  <input
                    type="file"
                    @change="
                      e => {
                        handleAdUploadImage(e, 'channelBottom');
                      }
                    "
                    accept=".jpeg,.png,.jpg,.bmp"
                    ref="channelBottom"
                    style="display:none"
                  />
                </div>
              </div>
              <div style="color:#99a9bf;width:300px;margin-left:100px">
                上传公众号文章底部、中部、小程序插屏广告图 <br />
                图片大小为<span style="color:red">960 * 540</span
                >像素,支持bmp,png,jpeg,jpg格式,不超过80KB
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" fx-start">
      <span class="w150">信息栏类型</span>
      <el-radio-group v-model="itemObj.headDescType">
        <el-radio :label="1">头像昵称型</el-radio>
        <el-radio :label="2">文案型</el-radio>
      </el-radio-group>
    </div>
    <div v-if="itemObj.headDescType === 2">
      <span class="w150">信息栏文案</span>
      <el-input
        v-model="itemObj.headDescription"
        placeholder="信息栏文案"
        maxlength="12"
        show-word-limit
        style="width:300px"
      ></el-input>
    </div>
    <div class="tags fx-start">
      <span class="w150">标签展示</span>
      <div>
        <el-radio-group v-model="radioTag">
          <el-radio :label="0">不启用</el-radio>
        </el-radio-group>
        <p style="color:#9c9c9c;">
          若启用标签展示，卡片外层将显示广告标签
        </p>
      </div>
    </div>
    <div class="dianji-tiaozhuan fx-start">
      <span class="w150">点击跳转</span>
      <el-radio-group v-model="radioJump">
        <el-radio :label="4">原生推广页</el-radio>
        <!-- <el-radio :label="6">公众号详情</el-radio> -->
      </el-radio-group>
    </div>
    <div class="dianji-anniu-tiaozhuan fx-start">
      <span class="w150">点击按钮跳转</span>
      <el-radio-group v-model="radioButtomJump" v-if="radioJump === 4">
        <el-radio :label="4">原生推广页</el-radio>
        <!-- <el-radio :label="40024">一键关注</el-radio> -->
      </el-radio-group>
      <span v-else style="color:rgb(101, 103, 110)">弹窗提示用户关注</span>
    </div>
    <div class="anniu-wenan fx-start">
      <span class="w150">按钮文案</span>
      <el-radio-group v-model="itemObj.radioText">
        <el-radio label="关注公众号"></el-radio>
        <el-radio label="立即咨询"></el-radio>
        <el-radio label="立即申请"></el-radio>
        <el-radio label="阅读小说"></el-radio>
      </el-radio-group>
    </div>
    <cropper-model
      :cropperVisible="cropperVisible"
      :imageUrl="needCropUrl"
      :trueWidth="trueWidth"
      :trueHeight="trueHeight"
      :fileName="fileName"
      :fixedBox="true"
      :needCropWidth="needCropWidth"
      :needCropHeight="needCropHeight"
      :myCropperWidth="myCropperWidth"
      :myCropperHeight="myCropperHeight"
      :cropTypeArr='cropTypeArr'
      @close="handleCropperClose"
      @cropSuccess="cropSuccess"
    ></cropper-model>
  </div>
</template>

<script>
import { uploadFile } from '@/api/common'
import cropperModel from './../components/cropper-model'
export default {
  name: 'channel-bottom',
  props: ['channelBottomData', 'posType'],
  data () {
    return {
      itemObj: {
        headDescType: 1,
        headDescription: '',
        imgUrl: [],
        radioText: '关注公众号'
      },
      radioTag: 1,
      radioJump: 4,
      radioButtomJump: 4,
      radioText: '关注公众号',
      needCropWidth: 0,
      needCropHeight: 0,
      fileName: '',
      needCropUrl: '',
      trueWidth: 0,
      trueHeight: 0,
      myCropperWidth: 0,
      myCropperHeight: 0,
      cropTypeObj: {
        type: '',
        index: null
      },
      cropTypeArr: [{
        width: 960,
        height: 540
      }],
      cropperVisible: false
    }
  },
  watch: {
    itemObj: {
      handler (newV) {
        this.$emit('handleChannelBottomData', newV, {
          mpTagType: this.radioTag,
          buttonParam: newV.radioText,
          clickJump: this.radioJump,
          clickBtnJump: this.radioButtomJump
        })
      },
      immediate: true,
      deep: true
    },
    posType: {
      handler (newV) {
        if (newV === 0) {
          this.echoData()
        }
      },
      immediate: true
    }
  },
  methods: {
    echoData () {
      this.itemObj.imgUrl = this.channelBottomData.descList
      this.itemObj.headDescType = this.channelBottomData.headDescType
      this.itemObj.headDescription = this.channelBottomData.headDescription
      this.itemObj.radioText = this.channelBottomData.buttonParam
      this.radioTag = this.channelBottomData.mpTagType
      this.radioJump = this.channelBottomData.clickJump
      this.radioButtomJump = this.channelBottomData.clickBtnJump
      // this.radioText = this.channelBottomData.buttonParam
    },
    handleAdUploadImage (e, refName) {
      const targetFile = e.target.files[0]
      if (targetFile.size > 2000 * 1024) {
        this.showErrorMsg('您上传的图片大小不符合要求,请重新上传!')
        this.$refs.channelBottom.value = ''
        return
      }

      const fr = new FileReader()
      fr.readAsDataURL(targetFile)
      fr.onload = event => {
        const image = new Image()
        image.src = event.currentTarget.result
        const src = image.src
        image.onload = () => {
          const { width, height } = image
          if (width < 960 || height < 540) {
            this.showErrorMsg('您上传的图片尺寸不符合要求,请重新上传!')
            this.$refs.channelBottom.value = ''
            return
          }
          this.trueWidth = width
          this.trueHeight = height
          this.needCropUrl = src
          this.needCropWidth = 960 / 2
          this.needCropHeight = 540 / 2
          this.myCropperWidth = 960 / 1.5
          this.myCropperHeight = 540 / 1.5
          this.fileName = targetFile.name
          this.cropTypeObj = {
            type: 'channelBottom',
            index: null
          }
          this.cropperVisible = true
        }
      }
    },
    uploadAdImage () {
      this.$refs.channelBottom.click()
    },
    delAdImage (index) {
      this.itemObj.imgUrl.splice(index, 1)
    },
    handleCropperClose () {
      this.$refs.channelBottom.value = ''
      this.cropperVisible = false
    },
    cropSuccess (file) {
      uploadFile({ file })
        .then(res => {
          this.itemObj.imgUrl.push(res)
        })
        .catch(err => {
          this.showErrorMsg(err)
        })
        .finally(() => {
          this.$refs.channelBottom.value = ''
        })
    },
    // 显示错误信息
    showErrorMsg (msg, type = 'error') {
      this.$message({
        showClose: true,
        message: msg,
        type
      })
    }
  },
  components: {
    cropperModel
  }
}
</script>
<style lang="scss" scoped>
.w150 {
  width: 150px !important;
}
.add-more {
  height: 36px;
  line-height: 36px;
  padding: 5px 13px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #e7f9ef;
  }
}
.custom-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  outline: none;
  width: 177px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-upload-input {
  }
}
.image-part {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column !important;
  .ad-image {
    width: 100%;
    .image-list {
      width: 100%;
      background: #f8f8f8;
      padding: 20px;
      > div:not(:nth-last-of-type(1)) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px 0;
        span {
          width: 100px;
        }
      }
    }
  }
}
.menu-container {
  > div {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > span:nth-of-type(1) {
      width: 100px;
    }
    .text-editor-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .editor-container {
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        > p {
          margin-bottom: 20px;
        }
        > p:nth-of-type(1) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .limit-statistics {
          color: #ccc;
          position: absolute;
          top: 25%;
          left: 69%;
          width: 80px;
          z-index: 2;
        }
      }
    }

    .ad-upload-image {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      > div {
        margin-top: 10px;
      }
      .upload-image {
        position: relative;
        width: 177px;
        height: 100px;
        margin-right: 10px;
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.3);
          justify-content: center;
          align-items: center;
          display: none;
          i {
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
        &:hover .mask {
          display: block;
        }
      }
    }
  }
  .fx-start {
    > span {
      align-self: flex-start;
    }
    line-height: 40px;
  }
}
</style>
