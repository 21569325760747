<template>
  <div class="main-container" ref="main-container">
    <div
      class="novel-plan-put-time part"
      id="novel-plan-put-time"
      style="margin-top:0"
      v-loading="loading"
    >
      <div class="highlight">投放时间</div>
      <div>
        <div>
          <span
            >投放日期
            <i
              class="iconfont icon-tishi"
              style="color:#D6D6D6"
              title="竞价购买的朋友圈信息流广告投放时间，可选范围6小时~60天"
            ></i
          ></span>
          <el-radio-group v-model="putSchedule">
            <el-radio :label="1">选择开始时间和结束时间</el-radio>
            <el-radio :label="2">长期投放</el-radio>
          </el-radio-group>
        </div>
        <div>
          <span>
            {{ putSchedule === 1 ? "开始与结束日期" : "开始日期" }}
          </span>
          <el-date-picker
            v-model="beginTime"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          <span v-if="putSchedule === 1">-</span>
          <el-date-picker
            v-model="endTime"
            v-show="putSchedule === 1"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>
        </div>
        <div>
          <span>投放日期</span>
          <el-radio-group v-model="customSchedule">
            <el-radio :label="1">全天投放</el-radio>
            <el-radio :label="2">每天相同时段</el-radio>
            <el-radio :label="3">自定义时段</el-radio>
          </el-radio-group>
        </div>
        <div v-show="customSchedule === 2">
          <span>每日投放时段</span>
          <el-time-select
            placeholder="起始时间"
            v-model="dayStartTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00'
            }"
          >
          </el-time-select>
          <span>-</span>
          <el-time-select
            placeholder="结束日期"
            v-model="dayEndTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00'
            }"
          >
          </el-time-select>
        </div>
        <div v-show="customSchedule === 3">
          <span>自定义时段</span>
          <div class="selected-schedule-list">
            <div
              class="selected-item"
              v-for="(item, index) in selectedList"
              :class="{ 'selected-hover': index === selectedItemIndex }"
              :key="index"
              @dblclick="handleSplice(item, index)"
              @mousemove="handleSelectedMove(index)"
              @mouseleave="selectedItemIndex = null"
            >
              {{ item }}
              <i
                @click="handleSplice(item, index)"
                class="el-icon-error"
                v-show="index === selectedItemIndex"
              ></i>
            </div>
          </div>
        </div>
        <div v-show="customSchedule === 3">
          <div class="custom-time-area">
            <p class="sumTime">每周共投放:{{ totalPutTime }}小时</p>
            <div class="time-block">
              <div class="time-header">
                <div class="time-info">时间</div>
                <div class="time-range-block">
                  <div class="time-range-block-list">
                    <p class="time-range">0:00-12:00</p>
                    <p class="time-range">12:00-24:00</p>
                  </div>
                  <ul class="time-hours" id="timeHours">
                    <li class="hour-num" v-for="n in 24" :key="n">
                      {{ n - 1 }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="time-body">
                <ul>
                  <li class="day-item">周一</li>
                  <li class="day-item">周二</li>
                  <li class="day-item">周三</li>
                  <li class="day-item">周四</li>
                  <li class="day-item">周五</li>
                  <li class="day-item">周六</li>
                  <li class="day-item">周日</li>
                </ul>
                <ul class="time-item-block">
                  <li
                    class="hour-item active-hour"
                    v-for="n in WEEK_DAYS * TIME_INTERVAL"
                    :col="
                      Math.ceil(n % TIME_INTERVAL) === 0
                        ? 47
                        : Math.ceil(n % TIME_INTERVAL) - 1
                    "
                    :row="Math.ceil(n / TIME_INTERVAL) - 1"
                    :key="n"
                    :title="
                      handleTitle(
                        Math.ceil(n % TIME_INTERVAL) === 0
                          ? 47
                          : Math.ceil(n % TIME_INTERVAL) - 1,
                        Math.ceil(n / TIME_INTERVAL) - 1
                      )
                    "
                    :class="{
                      'cell-hover':
                        cellItem.col === Math.ceil(n % TIME_INTERVAL) - 1 &&
                        cellItem.row === Math.ceil(n / TIME_INTERVAL) - 1
                    }"
                    @click="
                      cellClick(
                        Math.ceil(n % TIME_INTERVAL) === 0
                          ? 47
                          : Math.ceil(n % TIME_INTERVAL) - 1,
                        Math.ceil(n / TIME_INTERVAL) - 1,
                        n - 1
                      )
                    "
                  ></li>
                  <!-- @mousemove="cellMove(j-1,i-1)"
                            @mouseleave="cellLeave" -->
                </ul>
              </div>
            </div>
            <div class="daily-block">
              <p class="daily-title">每天</p>
              <ul class="daily-items">
                <li
                  v-for="n in 48"
                  :key="n"
                  class="daily-item selected-daily-item"
                  @click="dailyCellClick(n - 1)"
                ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="novel-plan-bidding-method part"
      id="novel-plan-bidding-method"
      v-loading="loading"
    >
      <div>出价方式</div>
      <div>
        <div class="methods">
          <template v-if="echoNovelPlanInfo.posType === 999">
            <span
              :class="{ 'color-grey': chooseMethods === 0 }"
              style="cursor:not-allowed"
              >CPM
            </span>
            <span
              :class="{ 'color-grey': chooseMethods === 1 }"
              @click="chooseMethods = 1"
              >智能优化(oCPM)</span
            >
          </template>
          <span
            v-if="echoNovelPlanInfo.posType === 101"
            :class="{ 'color-grey': chooseMethods === 1 }"
            @click="chooseMethods = 1"
          >
            智能优化(oCPC)
            <el-popover placement="top-start" width="300" trigger="hover">
              <div style="font-size:12px">
                <span style="margin-bottom:5px"
                  >针对您选择的优化目标设定广告出价,但不承诺最终成本。如果想获得智能优化（oCPC）权限，需最近的投放数据符合一定要求。</span
                >
                <br />
                <a href="https://ad.weixin.qq.com/guide/117" target="__blank"
                  >了解更多</a
                >
              </div>
              <i class="iconfont icon-tishi" slot="reference"></i>
            </el-popover>
          </span>
        </div>

        <div class="put-type flex-start" v-show="chooseMethods === 1">
          <span class="w150">投放模式</span>
          <el-radio-group v-model="radio1">
            <el-radio :label="1">均衡投放</el-radio>
            <el-radio :label="2">优先跑量</el-radio>
            <el-radio :label="3">优先成本</el-radio>
          </el-radio-group>
        </div>

        <div class="put-target flex-start" v-show="chooseMethods === 1">
          <span class="w150">优化目标</span>
          <el-radio-group v-model="radio2">
            <el-radio
              :label="item.id"
              v-for="item in targetList"
              :key="item.id"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="novel-plan-page part" id="novel-plan-page" v-loading="loading">
      <div class="part-plan-title">
        <span>选择推广页</span>

        <div class="add-page">
          <el-button class='button-small' type="primary" @click="choosePage">添加推广页</el-button>
        </div>
      </div>
      <div>
        <div class="page-list" v-if="pageList.length > 0">
          <div class="page-header page-item">
            <span>原生推广页</span>
            <span>适用样式</span>
            <span>操作</span>
          </div>
          <div v-for="(item, index) in pageList" :key="index" class="page-item">
            <span>{{ item.canvasName }}</span>
            <span>适用常规样式</span>
            <span
              ><span
                style="color:red;cursor: pointer;"
                @click="handleDelete(index)"
                >删除</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="novel-plan-setting-top part"
      id="novel-plan-setting-top"
      v-loading="loading"
    >
      <div class='part-plan-title'>
        <span>设置顶图</span>
        <div class="add-images">
          <el-button class='button-small'  type="primary" @click="addTopImages">添加图片</el-button>
        </div>
        <input
          type="file"
          ref="uploadTopImage"
          accept=".jpeg,.png,.jpg"
          @change="handleTopImageUpload"
          multiple
          style="display:none"
        />
      </div>
      <div>
        <div class="top-image-tips" v-if="echoNovelPlanInfo.posType === 999">
          <p>图片格式：大小不超过300KB，不支持GIF格式</p>
          <p>图片尺寸：<span style="color:red">800*800</span>像素</p>
          <p>单次最多上传6张图片</p>
        </div>
        <div class="top-image-tips" v-else>
          <p>图片格式：大小不超过300KB，不支持GIF格式</p>
          <p>
            图片尺寸：<span style="color:red">800 * 800</span>像素 /
            <span style="color:red">640 * 800</span>像素 /
            <span style="color:red">800 * 640</span>像素 /
            <span style="color:red">800 * 450</span>像素
          </p>
          <p>单次最多上传6张图片</p>
        </div>
        <div class="show-top-images" v-loading="topImageLoading">
          <div v-for="(image, index) in topImageList" :key="index">
            <img :src="image.topImg" alt="" />
            <div class="mask-hover">
              <i
                class="iconfont icon-lajitong"
                style="color:#fff;font-size:24px"
                @click="deleteTopImage(index)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="novel-plan-ad-setting part"
      id="novel-plan-ad-setting"
      v-show="!echoNovelPlanInfo.posType !== 8"
    >
      <div>外层广告设置</div>
      <div>
        <div class="ad-setting-menu">
          <div
            class="menu-item"
            :class="{
              'is-disabled': echoNovelPlanInfo.posType !== 999
            }"
          >
            <span>朋友圈信息流</span>
            <span
              :class="{ 'is-active': echoNovelPlanInfo.posType === 999 }"
            ></span>
          </div>
          <div
            class="menu-item"
            :class="{
              'is-disabled': echoNovelPlanInfo.posType !== 101
            }"
          >
            <span>优雅横版大图</span>
            <span
              :class="{ 'is-active': echoNovelPlanInfo.posType === 101 }"
            ></span>
          </div>
          <div
            class="menu-item"
            :class="{
              'is-disabled': echoNovelPlanInfo.posType !== 0
            }"
          >
            <span>公众号文章底部</span>
            <span
              :class="{ 'is-active': echoNovelPlanInfo.posType === 0 }"
            ></span>
          </div>
        </div>
        <div class="menu-container" v-if="echoNovelPlanInfo.posType === 999">
          <div>
            <span class="w150">广告样式</span>
            <span class="w150">常规广告</span>
          </div>
          <div>
            <span style="align-self:flex-start;margin-top:4px" class="w150"
              >外层文案
              <i
                class="iconfont icon-tishi"
                title="单个文案不能超过40个字符。
                    --请勿输入 \ < > [ ] # ^ & * 等上述特殊字符
                    --请勿在文案中间输入空格！"
              ></i
            ></span>
            <div class="text-editor-wrapper">
              <div
                class="editor-container"
                v-for="(item, index) in editorList"
                :key="item.id"
              >
                <p>
                  <span>这是新加的第一条文案~</span>
                  <i
                    class="el-icon-circle-close"
                    style="color:#D9D9D9;font-size:20px;cursor: pointer;"
                    @click="closeEditor(index)"
                  ></i>
                </p>
                <div
                  :id="`${item.id}`"
                  class="editor"
                  :class="{ showWarning: item.textLen > 40 }"
                ></div>
                <div class="limit-statistics">
                  {{ item.emojiLen }}/4 | {{ item.textLen }}/40
                </div>
                <p
                  v-show="item.textLen > 40"
                  style="color:rgb(242, 77, 77);font-size:13px"
                >
                  广告文案不得超过40字，请缩短输入内容
                </p>
                <p @click="addEditor" style="cursor: pointer;color:#9C9C9C;">
                  新添加一条文案
                </p>
                <!-- <p style='color:#F56C6C'>请勿输入 \ <  > [ ] # ^ & *  等上述特殊字符</p> -->
              </div>
            </div>
          </div>
          <div>
            <span class="w150">外层素材跳转</span>
            <span>原生推广页</span>
          </div>
          <div>
            <span class="w150">文字链文案</span>
            <span>了解公众号</span>
          </div>
          <div>
            <span class="w150">文字链跳转</span>
            <span>公众号详情</span>
          </div>
          <div>
            <span class="w150">数据展示</span>
            <el-switch v-model="switchValue"></el-switch>
          </div>
          <div v-if="switchValue">
            <span style="align-self:flex-start" class="w150">数据类型</span>
            <div>
              <el-radio v-model="radio3" :label="6">转化目标量</el-radio>
              <p style="color:#9C9C9C">
                展示本账户下相同推广目标的广告，近30日内的总转化目标量；数据 ≥50
                时展示
              </p>
            </div>
          </div>
          <div v-if="switchValue && radio3 === 6">
            <span style="align-self:flex-start" class="w150">转化行为</span>
            <div>
              <span>关注</span>
              <p style="color:#9C9C9C;margin-top:20px">
                需在详情页中展示相关行为的入口，否则广告将会被审核驳回
              </p>
            </div>
          </div>
        </div>
        <div class="menu-container" v-if="echoNovelPlanInfo.posType === 101">
          <div>
            <span class="w150">创意样式</span>
            <span>大图</span>
          </div>
          <div>
            <span class="w150">广告位置</span>
            <el-checkbox v-model="checkbox1"
              >公众号文章底部、中部，小程序插屏</el-checkbox
            >
          </div>
          <div
            style="display:flex;justify-content:space-between;align-items:center"
          >
            <span>广告位</span>
            <div class="add-ad-image flex-start add-more" @click="addImage">
              <svg
                class="adui-button-leftIcon adui-icon-base adui-icon-interactive_level_2"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="#fff"
                data-interactive="false"
                data-icon="add-circle"
              >
                <path
                  d="M9 16A7 7 0 119 2a7 7 0 010 14zm-.75-7.75H5v1.5div.25V13h1.5V9.75H13v-1.5H9.75V5h-1.5v3.25z"
                  fill-rule="evenodd"
                  fill="#00bf8a"
                ></path>
              </svg>
              <div style="color:#00bf8a;font-weight:bold">添加一组</div>
            </div>
          </div>
          <div class="image-part">
            <div class="ad-image">
              <div class="ad-image-container">
                <el-collapse v-model="activeNames">
                  <el-collapse-item
                    :name="`${index + 1}`"
                    v-for="(item, index) in adImagePartList"
                    :key="index"
                  >
                    <template slot="title">
                      <div
                        style="display:flex;justify-content:space-between;align-items:center;width:100%"
                      >
                        <div>
                          <span style="margin-right:20px;">图文标题</span
                          ><span>{{ item.title }}</span>
                        </div>
                        <div style="margin-right:70px">
                          <span @click="delAdItem(index)" style="color:red"
                            >删除</span
                          >
                        </div>
                      </div>
                    </template>
                    <div class="image-list" v-show="item.unfold">
                      <div>
                        <span style="color:#9C9C9C">广告位图片</span>
                        <div class="ad-upload-image">
                          <div
                            v-if="!item.imgUrl"
                            tabindex="0"
                            class="custom-upload"
                            @click="uploadAdImage(index)"
                          >
                            <i class="iconfont icon-xinzeng"></i>
                          </div>
                          <div class="upload-image" v-else>
                            <img :src="item.imgUrl" alt="" />
                            <div class="mask">
                              <i
                                class="el-icon-delete"
                                @click="delAdImage(index)"
                              ></i>
                            </div>
                          </div>
                          <input
                            type="file"
                            @change="
                              e => {
                                handleAdUploadImage(e, index);
                              }
                            "
                            accept=".jpeg,.png,.jpg,.bmp"
                            :ref="`adImage`"
                            style="display:none"
                          />
                          <div style="color:#99a9bf;">
                            上传公众号文章底部、中部、小程序插屏广告图 <br />
                            图片大小为<span style="color:red">960 * 540</span
                            >像素,支持bmp,png,jpeg,jpg格式,不超过80KB
                          </div>
                        </div>
                      </div>
                      <div>
                        <span style="margin-top:2px;color:#9C9C9C">标题</span>
                        <el-input
                          placeholder="请输入图文标题"
                          v-model="item.title"
                          maxlength="12"
                          show-word-limit
                          style="width:260px"
                        ></el-input>
                      </div>
                      <div>
                        <span style="margin-top:2px;color:#9C9C9C"
                          >广告文案
                          <i
                            class="iconfont icon-tishi"
                            title="广告文案奖展示于插屏广告"
                          ></i
                        ></span>
                        <div
                          style="display:flex;flex-direction:column;justify-contents:flex-start;align-items:flex-start"
                        >
                          <el-input
                            placeholder="请输入首行文案"
                            v-model="item.firstDesc"
                            maxlength="16"
                            show-word-limit
                            style="width:300px"
                          ></el-input>
                          <el-input
                            v-model="item.secondDesc"
                            placeholder="请输入次行文案"
                            maxlength="16"
                            show-word-limit
                            style="width:300px;margin-top:10px"
                          ></el-input>
                          <el-input
                            v-model="item.thirdDesc"
                            placeholder="请输入第三行文案(选填)"
                            maxlength="16"
                            show-word-limit
                            style="width:300px;margin-top:10px"
                          >
                          </el-input>
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
          <div class="info-line-type">
            <span class="w150">信息栏类型</span>
            <span>头像昵称型</span>
          </div>
          <div class="tags fx-start">
            <span class="w150">标签展示</span>
            <div>
              <el-radio-group v-model="radio4">
                <el-radio :label="0">不启用</el-radio>
                <el-radio :label="4">好友关注量标签</el-radio>
                <el-radio :label="8">转化目标量标签</el-radio>
              </el-radio-group>
              <p style="color:#9c9c9c;" v-if="radio4 === 0">
                若启用标签展示，卡片外层将显示广告标签
              </p>
              <p style="color:#9c9c9c" v-if="radio4 === 4">
                好友关注数量 ≥1 时展示
              </p>
              <p style="color:#9c9c9c" v-if="radio4 === 8">
                近30日本账户下同推广目标广告，转化目标量 ≥ 50时展示
              </p>
            </div>
          </div>
          <div class="dianji-tiaozhuan fx-start">
            <span class="w150">点击跳转</span>
            <el-radio-group v-model="radio5">
              <el-radio :label="4">原生推广页</el-radio>
              <el-radio :label="6">公众号详情</el-radio>
            </el-radio-group>
          </div>
          <div class="dianji-anniu-tiaozhuan fx-start">
            <span class="w150">点击按钮跳转</span>
            <el-radio-group v-model="radio6" v-if="radio5 === 4">
              <el-radio :label="4">原生推广页</el-radio>
              <el-radio :label="40024">一键关注</el-radio>
            </el-radio-group>
            <span v-else style="color:rgb(101, 103, 110)"
              >弹窗提示用户关注</span
            >
          </div>
          <div class="anniu-wenan fx-start">
            <span class="w150">按钮文案</span>
            <el-radio-group v-model="radio7">
              <el-radio :label="1">关注公众号</el-radio>
              <el-radio :label="2">立即咨询</el-radio>
              <el-radio :label="3">立即申请</el-radio>
              <el-radio :label="4">阅读小说</el-radio>
            </el-radio-group>
          </div>
        </div>
        <echo-channel-bottom
          v-if="channelBottomData && echoNovelPlanInfo.posType === 0"
          :posType="echoNovelPlanInfo.posType"
          :channelBottomData="channelBottomData"
          @handleChannelBottomData="handleChannelBottomData(arguments)"
        ></echo-channel-bottom>
      </div>
    </div>
    <div
      class="novel-plan-directional-crowd part"
      id="novel-plan-directional-crowd"
    >
      <div class="space-between" style="padding-top:0px">
        <span style="font-weight:bold">定向人群</span>
        <div
          class="add-more flex-start"
          @click="addMoreCrowd"
          style="color:#00bf8a;font-weight:bold"
        >
          <svg
            class="adui-button-leftIcon adui-icon-base adui-icon-interactive_level_2"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="#fff"
            data-interactive="false"
            data-icon="add-circle"
          >
            <path
              d="M9 16A7 7 0 119 2a7 7 0 010 14zm-.75-7.75H5v1.5div.25V13h1.5V9.75H13v-1.5H9.75V5h-1.5v3.25z"
              fill-rule="evenodd"
              fill="#00bf8a"
            ></path>
          </svg>
          <span>添加一组</span>
        </div>
      </div>
      <div>
        <el-collapse v-model="activeRegion">
          <el-collapse-item
            :name="`${index + 1}`"
            v-for="(crowd, index) in crowdInfoList"
            :key="index"
            v-show="!crowd.isDeleted"
          >
            <template slot="title">
              <div
                style="width:100%;display:flex;justify-content:space-between"
              >
                <div>
                  <span style="margin-right:20px">广告标题</span>
                  <span>{{ crowd.title }}</span>
                </div>
                <div>
                  <span
                    style="color:red;margin-right:70px"
                    @click="deleteCrowdInfo(index)"
                    >删除</span
                  >
                </div>
              </div>
            </template>
            <div class="crowd-container">
              <div class="crowd-title">
                <span class="w150">标题</span>
                <el-input
                  :ref="`crowdTitleInput`"
                  v-model="crowd.title"
                  placeholder="请输入广告名称"
                  size="large"
                >
                  <i
                    class="el-icon-edit el-input__icon"
                    slot="suffix"
                    @click="handleIconClick(crowd, index)"
                  >
                  </i>
                </el-input>
                <div class="operation"></div>
              </div>
              <div class="region">
                <span class="w150">地域</span>
                <el-radio-group v-model="crowd.areaType">
                  <el-radio :label="'area'">常住地</el-radio>
                </el-radio-group>
              </div>
              <!-- v-if改为v-show解决清空数据再次渲染时不能滚动的问题 -->
              <div
                class="already-choose-regions"
                v-show="crowd.area.length > 0"
              >
                <section>
                  <p>
                    <span style="color:#65676e;font-weight:bold">已选结果</span>
                    <span
                      @click="clearChooseRegion(index)"
                      style="color: rgb(20, 115, 204); cursor: pointer;"
                      >清空</span
                    >
                  </p>
                  <div class="choose-regions-list">
                    <div class="region-wrapper" style="max-height:85px">
                      <div class="container">
                        <div
                          class="region-item"
                          v-for="region in crowd.area"
                          :key="region.cid"
                        >
                          <span>{{ region.alias }}</span>
                          <i
                            class="iconfont icon-shanchu1"
                            @click="deleteRegionItem(region, index)"
                          >
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="choose-region">
                <div @click.stop>
                  <el-input
                    v-model="crowd.searchRegionText"
                    style="width:400px"
                    placeholder="搜索省，直辖市，行政区"
                    @focus="handleRegionFocus(index)"
                  >
                    <i
                      slot="append"
                      class="iconfont icon-icon-copy"
                      style="cursor:pointer"
                      @click="crowd.isShowRegionList = !crowd.isShowRegionList"
                    ></i>
                  </el-input>
                </div>
                <div>
                  <span
                    @click="addBatchRegion(index)"
                    style="color:#1473CC;cursor:pointer"
                    >批量添加地域</span
                  >
                </div>
                <div class="region-info-list" v-show="crowd.isShowRegionList">
                  <div class="region-container">
                    <div class="wrappers" style="height:260px;">
                      <div>
                        <div
                          class="region-choose-all"
                          @click="regionChooseAll(index)"
                        >
                          <i
                            class="iconfont icon-xuanze"
                            v-if="!crowd.isChooseAll"
                          ></i>
                          <i class="iconfont icon-xuanze2-copy" v-else></i>
                          <span style="font-weight:bold">全选</span>
                        </div>
                        <el-tree
                          :data="regionData"
                          :filter-node-method="filterNode"
                          :props="defaultProps"
                          :ref="`regionTree`"
                          highlight-current
                          show-checkbox
                          check-on-click-node
                          node-key="cid"
                          @check="
                            (data, checkedNode) => {
                              handleCheckChange(data, checkedNode, index);
                            }
                          "
                        >
                          <!-- @check-change="handleCheckChange(index)" -->
                        </el-tree>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="crowd-age crowd-limits">
                <span class="w150">年龄</span>
                <div class="age-limits">
                  <el-select
                    v-model="crowd.minAge"
                    placeholder="请选择"
                    style="width:80px"
                  >
                    <el-option
                      :label="age.name"
                      :value="age.id"
                      v-for="(age, index) in startAgeList"
                      :key="index"
                    ></el-option>
                  </el-select>
                  <span> - </span>
                  <el-select
                    v-model="crowd.maxAge"
                    placeholder="请选择"
                    style="width:100px"
                  >
                    <el-option
                      :label="age.name"
                      :value="age.id"
                      v-for="(age, index) in endAgeList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="crowd-gender crowd-limits">
                <span style="align-self:flex-start" class="w150">性别</span>
                <div class="gender-limits">
                  <el-radio-group v-model="crowd.gender">
                    <el-radio :label="1">全部</el-radio>
                    <el-radio :label="2">男</el-radio>
                    <el-radio :label="3">女</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="luoka-directional part" id="luoka-directional">
      <el-collapse v-model="activeLuoka">
        <el-collapse-item :name="1">
          <template slot="title">
            <span style="display:flex;justify-content:space-between"
              ><span style="font-weight:bold;">
                罗卡定向
              </span>
            </span>
          </template>
          <div class="radio-list">
            <div class="luoka-directional-part">
              <span class="w150">学历</span>
              <div>
                <el-radio-group v-model="radio9">
                  <el-radio :label="1">全部</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <div class="custom-type" v-if="radio9 === 2">
                  <el-checkbox-group v-model="educationLevel">
                    <el-checkbox label="DOCTOR">博士</el-checkbox>
                    <el-checkbox label="MASTER">硕士</el-checkbox>
                    <el-checkbox label="BACHELOR">本科</el-checkbox>
                    <el-checkbox label="JUNIOR_COLLEGE">专科</el-checkbox>
                    <el-checkbox label="SENIOR">高中</el-checkbox>
                    <el-checkbox label="JUNIOR">初中</el-checkbox>
                    <el-checkbox label="PRIMARY">小学</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">操作系统</span>
              <div>
                <el-radio-group v-model="radio10">
                  <el-radio :label="1">全部</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <div class="custom-type" v-if="radio10 === 2">
                  <el-checkbox-group v-model="phoneType">
                    <el-checkbox label="IOS">iOS 系统</el-checkbox>
                    <el-checkbox label="ANDROID">Android 系统</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">手机价格</span>
              <div>
                <el-radio-group v-model="radio11">
                  <el-radio :label="1">全部</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <div class="custom-type" v-if="radio11 === 2">
                  <el-checkbox-group v-model="phonePrice">
                    <el-checkbox label="1~14">1500一下</el-checkbox>
                    <el-checkbox label="15~24">1500 ~ 2500元</el-checkbox>
                    <el-checkbox label="25~34">2500 ~ 3500元</el-checkbox>
                    <el-checkbox label="35~44">3500 ~ 4500元</el-checkbox>
                    <el-checkbox label="45~100">4500元以上</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">手机品牌</span>
              <div>
                <el-radio-group v-model="radio12">
                  <el-radio :label="1">全部</el-radio>
                  <el-radio :label="2">定向</el-radio>
                  <el-radio :label="3">排除</el-radio>
                </el-radio-group>
                <div
                  class="custom-type"
                  v-show="radio12 === 2 || radio12 === 3"
                >
                  <div
                    class="already-brand-choose"
                    v-show="alreadyChooseBrandList.length > 0"
                  >
                    <section>
                      <p>
                        <span style="color:#65676e;font-weight:bold">
                          {{
                            radio12 === 2 ? "已选结果" : "已排除的结果"
                          }} </span
                        ><span
                          style="color:#1473CC;cursor:pointer"
                          @click="handleChooseAllBrand('clear')"
                          >清空</span
                        >
                      </p>
                      <div class="brand-list">
                        <div
                          class="brand-wrapper"
                          style="max-height:85px;position:relative"
                        >
                          <div class="container">
                            <div
                              class="phone-item"
                              v-for="(brand, index) in alreadyChooseBrandList"
                              :key="index"
                            >
                              <span>{{ brand.name }}</span>
                              <i
                                class="iconfont icon-shanchu1"
                                @click="deletePhoneItem(brand)"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="choose-brand">
                    <el-input
                      :placeholder="
                        `${
                          radio12 === 2
                            ? '添加手机定向品牌'
                            : '添加排除手机品牌'
                        }`
                      "
                      v-model="phoneBrandValue"
                      @focus="isShowBrandList = true"
                    >
                      <i
                        slot="append"
                        class="iconfont icon-icon-copy"
                        style="cursor:pointer"
                        @click="isShowBrandList = !isShowBrandList"
                      ></i>
                    </el-input>
                    <div class="phone-brand-list" v-if="isShowBrandList">
                      <div class="phone-container">
                        <div class="wrapper" style="height:260px;">
                          <ul ref="phoneBrand" class="ul-phone-list">
                            <li v-show="isShowChooseAllButton">
                              <label @click="handleChooseAllBrand">
                                <span style="font-weight:bold">全选</span>
                                <i
                                  v-if="!isChooseAllBrand"
                                  class="iconfont icon-xuanze"
                                ></i>
                                <i
                                  v-else
                                  class="iconfont icon-xuanze2-copy"
                                ></i>
                              </label>
                            </li>
                            <li
                              style="text-align:center;margin-top:20px;color:#ccc;"
                              v-if="brandList.length === 0"
                            >
                              <span>暂无相关匹配项</span>
                            </li>
                            <li v-for="item in brandList" :key="item.cid">
                              <label @click="handleBranchClick(item)">
                                <span>{{ item.name }}</span>
                                <i
                                  v-if="!item.checkStatus"
                                  class="iconfont icon-xuanze"
                                ></i>
                                <i
                                  v-else
                                  class="iconfont icon-xuanze2-copy"
                                ></i>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">运营商</span>
              <div>
                <el-radio-group v-model="radio13">
                  <el-radio :label="1">全部</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <div class="custom-type" v-if="radio13 === 2">
                  <el-checkbox-group v-model="telcom">
                    <el-checkbox label="CTC">电信</el-checkbox>
                    <el-checkbox label="CMC">移动</el-checkbox>
                    <el-checkbox label="CUC">联通</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">联网方式</span>
              <div>
                <el-radio-group v-model="radio14">
                  <el-radio :label="1">全部</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <div class="custom-type" v-if="radio14 === 2">
                  <el-checkbox-group v-model="connection">
                    <el-checkbox label="WIFI">Wi-Fi</el-checkbox>
                    <el-checkbox label="NET4G">4G</el-checkbox>
                    <el-checkbox label="NET3G">3G</el-checkbox>
                    <el-checkbox label="NET2G">2G</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">再营销</span>
              <div>
                <el-radio-group v-model="radio16">
                  <el-radio :label="1">不启用</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <p style="color:#a3a3a3">向与你的业务有过关联的用户投放广告</p>
                <div class="custom-type market" v-if="radio16 === 2">
                  <el-checkbox-group
                    v-model="inActionList"
                    @change="handleMarketingChange"
                  >
                    <el-checkbox
                      :label="101"
                      :disabled="notInActionList.includes(101)"
                      >已关注你的公众号</el-checkbox
                    >
                    <el-checkbox
                      :label="401"
                      :disabled="notInActionList.includes(401)"
                      >曾领取你的微信卡券</el-checkbox
                    >
                    <el-checkbox
                      :label="301"
                      :disabled="notInActionList.includes(301)"
                      >曾对你的公众号广告感兴趣</el-checkbox
                    >
                    <el-checkbox
                      :label="501"
                      :disabled="notInActionList.includes(501)"
                      >曾对你的朋友圈广告感兴趣</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="luoka-directional-part">
              <span class="w150">排除营销</span>
              <div>
                <el-radio-group v-model="radio17">
                  <el-radio :label="1">不启用</el-radio>
                  <el-radio :label="2">自定义</el-radio>
                </el-radio-group>
                <p style="color:#a3a3a3">排除与你的业务有过关联的用户</p>
                <div class="custom-type market" v-if="radio17 === 2">
                  <el-checkbox-group v-model="notInActionList">
                    <el-checkbox
                      :label="101"
                      :disabled="inActionList.includes(101)"
                      >已关注你的公众号</el-checkbox
                    >
                    <el-checkbox
                      :label="401"
                      :disabled="inActionList.includes(401)"
                      >曾领取你的微信卡券</el-checkbox
                    >
                    <el-checkbox
                      :label="301"
                      :disabled="inActionList.includes(301)"
                      >曾对你的公众号广告感兴趣</el-checkbox
                    >
                    <el-checkbox
                      :label="501"
                      :disabled="inActionList.includes(501)"
                      >曾对你的朋友圈广告感兴趣</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <choose-page
      :isShowChoosePage.sync="isShowChoosePage"
      @close="
        () => {
          this.isShowChoosePage = false;
        }
      "
      @confirm="confirm"
    >
    </choose-page>
    <div class="next-step" id="echo-next-step-page-one">
      <el-button type="info" size="large" @click="$emit('goBack')"
        >上一步</el-button
      >
      <el-button type="primary" size="large" @click="nextStep"
        >下一步</el-button
      >
    </div>
    <batch-add-region-history
      @success="handleRegionSuccess"
      :isShowRegionHistory.sync="isShowRegionHistory"
      @close="isShowRegionHistory = false"
    ></batch-add-region-history>

    <cropper-model
      :cropperVisible="cropperVisible"
      :imageUrl="needCropUrl"
      :trueWidth="trueWidth"
      :trueHeight="trueHeight"
      :fileName="fileName"
      :fixedBox="true"
      :needCropWidth="needCropWidth"
      :needCropHeight="needCropHeight"
      :myCropperWidth="myCropperWidth"
      :myCropperHeight="myCropperHeight"
      :cropTypeArr="cropTypeArr"
      @close="handleCropperClose"
      @cropSuccess="cropSuccess"
    ></cropper-model>
    <back-up-down
      targetUp="#novel-plan-put-time"
      targetDown="#echo-next-step-page-one"
    ></back-up-down>
  </div>
</template>

<script>
import choosePage from './../addNovelPlan/children/choose-page'
import batchAddRegionHistory from './../components/batch-add-region-history'
import echoChannelBottom from './echo-channel-bottom'
import backUpDown from './../components/back-up-down'
import E from 'wangeditor'
import BScroll from 'better-scroll'
import { getWeChatRegion } from '@/api/novelPut'
import { uploadFile } from '@/api/common'
import { mapMutations, mapState } from 'vuex'
import cropperModel from './../components/cropper-model'
const defaultInfo = {
  minAge: 14,
  maxAge: 66,
  gender: 1, // 性别默认全部
  id: 1,
  areaType: 'area', // 默认常住地
  searchRegionText: '', // 搜索内容
  isShowRegionList: false,
  title: '', // 标题
  unfold: true, // 是否展开
  // 已选择的区域
  area: [],
  // 传输的地域数据(接口传输)
  transferAreaData: [],
  isDeleted: false,
  isChooseAll: false
}

export default {
  name: 'stepOne',
  props: ['checkList', 'curStep'],
  data () {
    return {
      WEEK_DAYS: 7,
      TIME_INTERVAL: 48,
      isShowLuoka: false,
      cropperVisible: false,
      targetScrollHeight: 0,
      loading: true,
      // 外层广告配置信息(朋友圈信息流文案)
      planGroupTypeOne: {
        linkName: '了解公众号',
        linkPageType: 6, // 默认公众号详情
        snsDynamicShowType: 0, // 数据类型
        snsDynamicShowText: '', // 转化行为
        desc: [] // 文案
      },
      // 外层广告配置信息(公众号平台流量信息流)
      planGroupTypeTwo: {
        mpTagType: 0, // 标签展示(默认不启用)
        buttonParam: '关注公众号', // 按钮文案 (默认关注公众号)
        clickJump: 4, // 点击跳转 4 原生推广页 6 公众号详情页
        clickBtnJump: 0, // 0 原生推广页 40024 一键关注
        desc: []
      },
      crowdPartNum: 0,
      putSchedule: 1,
      customSchedule: 1,
      topImageList: [], // 顶图
      beginTime: new Date(),
      dayStartTime: '00:00', // 每日投放时间段 开始日期
      endTime: '',
      dayEndTime: '24:00', // 每日投放时间段 结束日期
      totalPutTime: 168, // 每周一共投放时间
      timeset: [],
      timesetEctype: [],
      selectedList: [], // 选择的时间段 根据单元格的变化动态变化
      selectedItemIndex: null,
      perDailyCellStatus: [], // "每天" 单元格是否被点击的状态(0:未点击 1:点击)
      perCellStatus: [], // 单元格是否被点击的状态(0:未点击 1:点击)
      firstLineText: '',
      secondLineText: '',
      thirdLineText: '',
      imageTitle: '', // 图文标题
      adImagePartList: [], // 广告位图片列表
      week: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      radio1: 2,
      radio2: 6,
      radio3: 0, // 转化目标量
      radio4: 0,
      radio5: 4,
      radio6: 4,
      radio7: 1,
      radio8: 1,
      radio9: 1, // 学历
      radio10: 1, // 操作系统
      radio11: 1, // 手机价格
      radio12: 1, // 手机品牌
      radio13: 1, // 运营商
      radio14: 1, // 联网方式
      radio15: 1, // 付费用户类型
      radio16: 1, // 再营销
      radio17: 2, // 排除营销

      area: [], // 已选择的地区
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      phoneProps: {
        label: 'name'
      },
      regionIndex: '',
      scroll: null, // better-scroll =>地域选择器
      regionScroll: null, // better-scroll => 地域(已选择)
      phoneScroll: null,
      initPhoneScrollFlag: false,
      brandScroll: null,
      initBrandScrollFlag: false,
      isShowRegionList: false,
      regionData: [],
      searchRegionText: '',
      pageList: [], // 推广页列表
      chooseMethods: 1, // 默认oCPM
      editor: null,
      isShowChoosePage: false,
      defaultActive: '1',
      editorSuffix: 1,
      switchValue: false,
      checkbox1: true,
      minAge: 14, // 选择年龄时的开始范围
      maxAge: 99, // 选择年龄时的结束范围
      startAgeList: [],
      endAgeList: [],
      crowdInfoList: [], // 定向人群信息
      educationLevel: [], // 学历
      phoneType: [], // 手机操作系统
      phonePrice: [], // 手机价格
      alreadyChooseBrandList: [], // 已选择的手机品牌
      isChooseAllBrand: false, // 是否点击了全选手机品牌的按钮
      brandList: [], // 手机品牌列表
      copyBrandList: [], // 创建手机品牌列表的副本 用于 输入框筛选清空筛选项时进行还原
      phoneBrandValue: '', // 手机输入框绑定值
      isShowChooseAllButton: true, // 是否应该在手机列表中显示全选按钮
      emojiList: [], // 表情列表
      telcom: [], // 运营商
      connection: [], // 联网方式
      inActionList: [], // 再营销
      notInActionList: [101], // 排除营销
      copyNotInActionList: [], // 创建"排除营销"模块的内容的副本
      editorArrList: [], // 内容编辑器变量合集
      isShowBrandList: false, // 是否显示手机品牌
      // specialCharacterExp: /[\\<>\[\]#^*]/gm, // 文案判断测试字符标识
      // isIncludesSpecialFlag:false,  // 是否含有特殊字符
      topImageLoading: false, // 顶图loading
      isShowRegionHistory: false, // 批量添加地域
      activeNames: ['1'],
      activeRegion: ['1'],
      activeLuoka: ['1'],
      channelBottomData: null, // 公众号文章底部
      needCropWidth: 0,
      needCropHeight: 0,
      fileName: '',
      needCropUrl: '',
      trueWidth: 0,
      trueHeight: 0,
      myCropperWidth: 0,
      myCropperHeight: 0,
      cropTypeArr: [],
      cropTypeObj: {
        type: '',
        index: null
      },
      cellItem: {
        col: null,
        row: null
      },
      editorList: [
        // {
        //   id: 'inputText-1',
        //   html: '', // html格式的数据
        //   value: '', // 去除html标签之后的数据
        //   emojiLen: 0, // 表情个数
        //   textLen: 0 // 文字长度
        // }
      ],
      timePeriod: [
        '00:00 - 00:30',
        '00:30 - 01:00',
        '01:00 - 01:30',
        '01:30 - 02:00',
        '02:00 - 02:30',
        '02:30 - 03:00',
        '03:00 - 03:30',
        '03:30 - 04:00',
        '04:00 - 04:30',
        '04:30 - 05:00',
        '05:00 - 05:30',
        '05:30 - 06:00',
        '06:00 - 06:30',
        '06:30 - 07:00',
        '07:00 - 07:30',
        '07:30 - 08:00',
        '08:00 - 08:30',
        '08:30 - 09:00',
        '09:00 - 09:30',
        '09:30 - 10:00',
        '10:00 - 10:30',
        '10:30 - 11:00',
        '11:00 - 11:30',
        '11:30 - 12:00',
        '12:00 - 12:30',
        '12:30 - 13:00',
        '13:00 - 13:30',
        '13:30 - 14:00',
        '14:00 - 14:30',
        '14:30 - 15:00',
        '15:00 - 15:30',
        '15:30 - 16:00',
        '16:00 - 16:30',
        '16:30 - 17:00',
        '17:00 - 17:30',
        '17:30 - 18:00',
        '18:00 - 18:30',
        '18:30 - 19:00',
        '19:00 - 19:30',
        '19:30 - 20:00',
        '20:00 - 20:30',
        '20:30 - 21:00',
        '21:00 - 21:30',
        '21:30 - 22:00',
        '22:00 - 22:30',
        '22:30 - 23:00',
        '23:00 - 23:30',
        '23:30 - 24:00'
      ],
      targetList: [
        {
          value: '点击',
          id: 2
        },
        {
          value: '关注',
          id: 6
        },
        {
          value: '下单',
          id: 7
        },
        {
          value: '跳转按钮点击',
          id: 10
        },
        {
          value: '公众号内阅读',
          id: 14
        },
        {
          value: '关键页面访问',
          id: 23
        },
        {
          value: '公众号内注册',
          id: 25
        },
        {
          value: '公众号内发消息',
          id: 26
        },
        {
          value: '授信',
          id: 32
        },
        {
          value: '加企业微信客服',
          id: 36
        }
      ],
      idNameList: [
        'novel-plan-put-time',
        'novel-plan-bidding-method',
        'novel-plan-page',
        'novel-plan-setting-top',
        'novel-plan-ad-setting',
        'novel-plan-directional-crowd',
        'luoka-directional'
      ]
    }
  },
  computed: {
    ...mapState(['echoNovelPlanInfo']),
    // 获取定向人群里面input框的值
    crowdText () {
      return this.crowdInfoList.map(item => item.searchRegionText)
    }
  },
  async mounted () {
    await this.initData()
    document.querySelector('#echo-home-page').onclick = e => {
      this.hiddenTargetDom(e)
    }
    this.$on('hook:beforeDestroy', () => {
      if (document.querySelector('#echo-home-page')) {
        document.querySelector('#echo-home-page').onclick = null
      }
    })
  },
  methods: {
    ...mapMutations([
      'setCrowdInfoList',
      'delCrowdInfoList',
      'setAdPartList',
      'setTopImages',
      'setEchoNovelPlanInfo',
      'setIsEditTopImages'
    ]),
    handleSelectedMove (index) {
      this.selectedItemIndex = index
    },
    handleSplice (item) {
      const targetIndex = this.selectedList.findIndex(
        selected => selected === item
      )

      if (targetIndex > -1) {
        this.selectedList.splice(targetIndex, 1)
      }
      const rowValue = item.slice(0, 2)
      if (rowValue !== '每天') {
        const row = this.week.findIndex(target => target === rowValue)
        const startTime = item.slice(3, 8)
        const endTime = item.slice(-5)
        const arr1 = this.timePeriod.filter(target =>
          target.includes(startTime)
        )
        const arr2 = this.timePeriod.filter(target => target.includes(endTime))
        // ! 取得起始时间的索引和结束时间的索引
        const startIndex = this.timePeriod.findIndex(
          item => item === (arr1[1] ? arr1[1] : arr1[0])
        )
        const endIndex = this.timePeriod.findIndex(item => item === arr2[0])
        for (let i = startIndex; i <= endIndex; i++) {
          const index = i + row * 48
          this.cellClick(i, row, index)
        }
        return
      }

      if (rowValue === '每天') {
        const copyTimeset = this.deepClone(this.timesetEctype)
        const startCol = this.timePeriod.findIndex(target => {
          return target.slice(0, 5) === item.slice(3, 8)
        })
        const endCol = this.timePeriod.findIndex(target => {
          return target.slice(-5) === item.slice(-5)
        })
        const dailyItems = [...document.querySelectorAll('.daily-item')]
        const hourItemList = [...document.querySelectorAll('.hour-item')]
        for (let i = 0; i < dailyItems.length; i++) {
          if (i >= startCol && i <= endCol) {
            const dom = dailyItems[i]
            dom.classList.remove('selected-daily-item')
          }
        }
        for (let j = 0; j < hourItemList.length; j++) {
          const dom = hourItemList[j]
          const col = Number(dom.getAttribute('col'))
          // const row = Number(dom.getAttribute('row'))
          if (col >= startCol && col <= endCol) {
            dom.classList.remove('active-hour')
          }
        }

        for (let i = startCol; i <= endCol; i++) {
          for (let j = 0; j < this.WEEK_DAYS; j++) {
            const index = j * 48 + i
            this.changeSelectedList(i, j)
            copyTimeset[index] = 0
          }
        }
        this.timesetEctype = copyTimeset
      }
    },
    // !点击单元格变换样式
    cellClick (col, row, targetIndex) {
      // !改变单元格的样式
      this.changeCellStyle(col, row)
      // !改变"每天"单元格的样式
      this.changeDailyCellStyle(col, row)
      this.changeSelectedList(col, row)
      this.changePerTimeSet(col, row, targetIndex)
    },
    // 当鼠标在单元格上滑动的时候
    cellMove (col, row) {
      this.$set(this.cellItem, 'col', col)
      this.$set(this.cellItem, 'row', row)
    },
    cellLeave () {
      this.$set(this.cellItem, 'col', null)
      this.$set(this.cellItem, 'row', null)
    },
    changeDailyCellStyle (col) {
      let flag = true // !对于"每天"的单元格来说需要判断单元格的整列是否是全选和被全选来删除或者是添加样式
      const dailyItems = [...document.querySelectorAll('.daily-item')]
      const hourItemList = [...document.querySelectorAll('.hour-item')]

      for (let i = 0; i < hourItemList.length; i++) {
        const dom = hourItemList[i]
        if (Number(dom.getAttribute('col')) === col) {
          if (!dom.classList.contains('active-hour')) {
            flag = false
          }
        }
      }
      for (let i = 0; i < dailyItems.length; i++) {
        if (i === col) {
          const dom = dailyItems[i]
          if (dom.classList.contains('selected-daily-item')) {
            dom.classList.remove('selected-daily-item')
          } else {
            // ! 只有满足单列全部都有'active-hour'类名才能添加
            if (flag) {
              dom.classList.add('selected-daily-item')
            }
          }
        }
      }
    },
    dailyCellClick (colInfo) {
      let isContainsClassNameSelected = false
      const hourItemList = [...document.querySelectorAll('.hour-item')]
      const dailyItems = [...document.querySelectorAll('.daily-item')]
      const targetDailyItem = dailyItems[colInfo]
      if (targetDailyItem.classList.contains('selected-daily-item')) {
        targetDailyItem.classList.remove('selected-daily-item')
      } else {
        targetDailyItem.classList.add('selected-daily-item')
        isContainsClassNameSelected = true
      }

      for (let i = 0; i < hourItemList.length; i++) {
        const dom = hourItemList[i]
        if (Number(dom.getAttribute('col')) === colInfo) {
          isContainsClassNameSelected === true
            ? dom.classList.add('active-hour')
            : dom.classList.remove('active-hour')
        }
      }
      this.$nextTick(() => {
        hourItemList.forEach((item, index) => {
          this.$set(
            this.timesetEctype,
            index,
            item.classList.contains('active-hour') ? 1 : 0
          )
        })
      })
      this.changeSelectedList()
    },
    changeCellStyle (col, row) {
      const hourItemList = [...document.querySelectorAll('.hour-item')]
      for (let i = 0; i < hourItemList.length; i++) {
        const dom = hourItemList[i]
        if (
          Number(dom.getAttribute('col')) === col &&
          Number(dom.getAttribute('row')) === row
        ) {
          if (dom.classList.contains('active-hour')) {
            dom.classList.remove('active-hour')
            if (dom.classList.contains('cell-hover')) {
              dom.classList.remove('cell-hover')
            }
          } else {
            dom.classList.add('active-hour')
          }
          return
        }
      }
    },
    // !改变自定义时间段的显示合集
    changeSelectedList (col, row) {
      let arr1 = [] // 显示存放每天的时间段
      let arr = [] // 显示存放当天特定的时间段
      const customMap = [
        '每天',
        '周一',
        '周二',
        '周三',
        '周四',
        '周五',
        '周六',
        '周日'
      ]
      this.selectedList = []
      arr = this.getArr(col, row)
      arr1 = this.getArr1()
      let res = [...arr1, ...arr]
      // !根据自定义的customMap来排序
      if (res.length > 0) {
        res = res
          .map(x => ({
            value: x,
            index: customMap.findIndex(map => x.includes(map))
          }))
          .sort((a, b) => a.index - b.index)
          .map(item => item.value)
        this.selectedList = this.customArrSort(res)
      }
    },
    customArrSort (arr) {
      const copyArr = this.deepClone(arr)
      // !重复数据(用于后续删除)
      const needSpliceItem = []
      for (let i = 0; i < copyArr.length; i++) {
        // !根据空格来截断数据
        const val = copyArr[i].split(' ')
        if (val[1] === val[3]) {
          needSpliceItem.push(copyArr[i])
        }
      }

      // !删除首尾时间重复的数据
      for (let i = 0; i < needSpliceItem.length; i++) {
        const val = needSpliceItem[i]
        const targetIndex = copyArr.findIndex(item => item === val)
        copyArr.splice(targetIndex, 1)
      }
      // !将数据合并为二维数组
      const weekArr = []
      // !需要单独为weekArr设置初始下标weekIndex = 0
      let weekIndex = 0
      // !如果只有一个元素
      if (copyArr.length === 1) {
        weekArr[0] = [copyArr[0]]
      }
      // ! 如果有多个元素
      for (let i = 0; i < copyArr.length - 1; i++) {
        const val1 = copyArr[i].split(' ')
        const val2 = copyArr[i + 1].split(' ')
        if (!Array.isArray(weekArr[weekIndex])) {
          weekArr[weekIndex] = []
        }
        if (!Array.isArray(weekArr[weekIndex + 1])) {
          weekArr[weekIndex + 1] = []
        }
        if (val1[0] === val2[0]) {
          // !防止weekArr添加相同的元素
          if (!weekArr[weekIndex].find(week => week === copyArr[i])) {
            weekArr[weekIndex].push(copyArr[i])
          }
          // !防止漏掉push最后一个元素
          if (i + 1 === copyArr.length - 1) {
            weekArr[weekIndex].push(copyArr[i + 1])
          }
        } else {
          // !防止添加相同的元素
          if (!weekArr[weekIndex].find(week => week === copyArr[i])) {
            weekArr[weekIndex].push(copyArr[i])
          }
          // !如果val1[0]!==val2[0]除了 给当前的weekIndex下标push copyArr[i]
          // ! 还需要给weekIndex++ 的下标push copyArr[i+1]
          weekIndex++
          weekArr[weekIndex].push(copyArr[i + 1])
        }
      }
      // !weekArr.pop();
      const customArr = []
      for (let i = 0; i < weekArr.length; i++) {
        const weekTime = weekArr[i]
        if (weekArr[i].length === 1) {
          customArr.push(weekArr[i][0])
        }
        for (let j = 0; j < weekTime.length - 1; j++) {
          let flag = true
          let val = ''
          const val1 = weekTime[j].split(' ')
          let startVal = val1[1]
          let endVal = val1[3]
          // !如果第一个结尾时间跟第二个开始时间相等，则需要合并时间段
          for (let m = j + 1; m < weekTime.length; m++) {
            const val2 = weekTime[m].split(' ')
            // !如果前一个数据的末尾时间等于第二个数据的开头时间 需要将endVal往后移
            if (endVal === val2[1]) {
              endVal = val2[3]
              // ! 如果后续的weekTime[m] 都是连续的时间段 则到最后一个元素的时候需要合并整个值
              if (m === weekTime.length - 1) {
                customArr.push(`${val1[0]} ${startVal} - ${endVal}`)
                flag = false
              }
            } else {
              val = `${val1[0]} ${startVal} - ${endVal}`
              customArr.push(val)
              //! 将startVal和endVal往后移
              startVal = val2[1]
              endVal = val2[3]
              j = m - 1
              if (m === weekTime.length - 1) {
                customArr.push(`${val1[0]} ${startVal} - ${endVal}`)
                flag = false
              }
            }
          }
          if (!flag) {
            break
          }
        }
      }

      return customArr
    },
    getArr (col, row) {
      const arr = []
      const cellInfo = []
      const hourItemList = [...document.querySelectorAll('.hour-item')]
      for (let i = 0; i < hourItemList.length; i++) {
        const dom = hourItemList[i]
        if (!dom.classList.contains('active-hour')) {
          const col = Number(dom.getAttribute('col'))
          const row = Number(dom.getAttribute('row'))
          const targetTimeSchedule = this.timePeriod[col]
          const targetWeek = this.week[row]
          cellInfo.push({
            col,
            row,
            targetTimeSchedule,
            targetWeek
          })
        }
      }
      const obj = cellInfo.reduce((acc, b, c) => {
        if (!(`${b.targetTimeSchedule}` in acc)) {
          acc[`${b.targetTimeSchedule}`] = []
        }
        acc[`${b.targetTimeSchedule}`].push(b.targetWeek)
        return acc
      }, {})
      Object.keys(obj).forEach((item, m) => {
        const value = obj[item]
        // ! 取反操作 push一周当中除开当天(或者几天)的同一时段
        const targetArr = this.week.filter(we => !value.includes(we))
        targetArr.forEach((target, n) => {
          arr.push(`${target} ${item}`)
        })
      })
      return arr || []
    },
    getArr1 () {
      const arr1 = []
      const dailyItems = [...document.querySelectorAll('.daily-item')]
      const intervalArr = []
      for (let i = 0; i < dailyItems.length; i++) {
        const dom = dailyItems[i]
        // !如果没有 selected-daily-item 表示在这里有间隔
        if (!dom.classList.contains('selected-daily-item')) {
          intervalArr.push(i)
        }
      }
      if (intervalArr.length === 0) {
        this.selectedList = ['每天 00:00 - 24:00']
        return []
      }
      for (let i = 0; i <= intervalArr.length; i++) {
        if (i === 0) {
          arr1.push(
            `每天 00:00 - ${this.timePeriod[intervalArr[i]].slice(0, 5)}`
          )
        } else if (i === intervalArr.length) {
          arr1.push(
            `每天 ${this.timePeriod[intervalArr[i - 1]].slice(-5)} - 24:00`
          )
        } else {
          arr1.push(
            `每天 ${this.timePeriod[intervalArr[i - 1]].slice(
              -5
            )} - ${this.timePeriod[intervalArr[i]].slice(0, 5)}`
          )
        }
      }
      if (intervalArr.includes(0)) {
        arr1.shift()
      }
      if (intervalArr.includes(47)) {
        arr1.pop()
      }
      return arr1 || []
    },
    async initData () {
      this.regionData = await getWeChatRegion({ type: 1 })
      const brandList = await getWeChatRegion({ type: 2 })
      this.emojiList = await getWeChatRegion({ type: 3 })
      this.setIsEditTopImages(false)
      this.loading = false
      // 传回的时间戳是秒为单位 需要转化成毫秒
      this.beginTime = new Date(this.echoNovelPlanInfo.beginTime * 1000)
      const endTime = new Date(Number(`${this.echoNovelPlanInfo.endTime}999`))
      if (this.echoNovelPlanInfo.endTime === 0) {
        this.putSchedule = 2
        // !如果回显的类型是长期投放，则需要重置endTime为当前日期往后推29天
        this.endTime = new Date(+this.beginTime + 1000 * 60 * 60 * 24 * 30 - 1)
      } else {
        this.endTime = new Date(endTime)
      }
      const timeset = this.echoNovelPlanInfo.timeset
      this.customSchedule = this.deepClone(this.echoNovelPlanInfo.timesetType)
      // !回显自定义时间段dom样式
      if (this.customSchedule === 3) {
        if (typeof this.echoNovelPlanInfo.selectedList === 'string') {
          this.selectedList = this.deepClone(
            JSON.parse(this.echoNovelPlanInfo.selectedList)
          )
        } else {
          this.selectionList = this.deepClone(
            this.echoNovelPlanInfo.selectionList
          )
        }
        this.timeset = timeset.split('').map(item => Number(item))
        this.timesetEctype = this.deepClone(this.timeset)
        this.echoCustomTimeDom(timeset)
      }
      // !回显"每天相同时段"
      if (this.customSchedule === 2) {
        this.selectedList = ['每天 00:00-24:00']
        const copyTimeset = timeset
          .split('')
          .map(item => Number(item))
          .slice(0, 48)
        const startIndex = copyTimeset.indexOf(1)
        const endIndex = copyTimeset.lastIndexOf(1)
        this.dayStartTime = this.timePeriod[startIndex].slice(0, 5)
        this.dayEndTime = this.timePeriod[endIndex].slice(-5)
        this.timeset = timeset.split('').map(item => Number(item))
        this.timesetEctype = Array.from({ length: 48 * 7 }, () => 1)
      }

      if (this.customSchedule === 1) {
        // !timeset 为空 则 表示投放日期选择的是全天投放
        this.selectedList = ['每天 00:00-24:00']
        this.timeset = Array.from({ length: 48 * 7 }, () => 1)
        this.timesetEctype = this.deepClone(this.timeset)
      }

      // !回显出价方式
      this.chooseMethods = this.echoNovelPlanInfo.bidActionType
      // !回显投放模式
      this.radio1 = this.echoNovelPlanInfo.bidStrategy
      // !回显优化目标
      this.radio2 = this.echoNovelPlanInfo.bidObjective
      const targetIndex = this.targetList.findIndex(item => item.id === 2)
      if (this.echoNovelPlanInfo.posType !== 999) {
        this.targetList.splice(targetIndex, 1)
      } else {
        if (targetIndex === -1) {
          this.targetList.unshift({
            value: '点击',
            id: 2
          })
        }
      }
      // !回显推广页
      this.pageList = this.deepClone(this.echoNovelPlanInfo.pages)
      // !回显顶图
      this.topImageList = this.deepClone(this.echoNovelPlanInfo.planTopImgs)

      // ! 回显外层广告设置

      if (this.echoNovelPlanInfo.posType === 101) {
        this.adImagePartList = this.deepClone(
          this.echoNovelPlanInfo.planGroup.descList.map(item => {
            return {
              ...item,
              unfold: true
            }
          })
        )
      } else {
        this.adImagePartList = [
          {
            imgUrl: '', // 图片线上链接
            title: '', // 图片标题
            firstDesc: '', // 首行文案
            secondDesc: '', // 次行文案
            thirdDesc: '', // 第三行文案
            unfold: true
          }
        ]
      }

      // !针对朋友圈信息流回显
      if (this.echoNovelPlanInfo.posType === 999) {
        const copyPlanGroup = this.deepClone(this.echoNovelPlanInfo.planGroup)
        const { snsDynamicShowType } = copyPlanGroup
        if (snsDynamicShowType === 6) {
          this.switchValue = true
          this.radio3 = 6
        }
        // const id = this.editorList[this.editorList.length - 1].id;
        // this.createEditor(id);
        JSON.parse(copyPlanGroup.desc).forEach((item, index) => {
          const html = copyPlanGroup.descRichTextList[index]
          const textValue = html.replace(/<\/?.+?>/g, '')
          const imgRegExp = /<img[^>]+>/g
          const emojiArr = html.match(imgRegExp)
          const eLen = emojiArr ? emojiArr.length : 0
          const textLen = textValue.length + eLen
          const obj = {
            id: `inputText-${this.editorSuffix}`,
            html: html,
            value: item,
            emojiLen: eLen,
            textLen: textLen
          }
          this.editorList.push(obj)
          this.editorSuffix++
        })
        this.$nextTick(() => {
          this.echoEditorItem()
        })
      }

      // !针对公众号流量回显
      if (this.echoNovelPlanInfo.posType === 101) {
        // !回显 标签展示(公众号)
        this.radio4 = this.echoNovelPlanInfo.planGroup.mpTagType
          ? this.echoNovelPlanInfo.planGroup.mpTagType
          : 0
        // !回显"点击跳转"(公众号)
        this.radio5 = this.echoNovelPlanInfo.planGroup.clickJump
          ? this.echoNovelPlanInfo.planGroup.clickJump
          : 4
        // ! 回显"点击按钮跳转"(公众号)
        this.radio6 = this.echoNovelPlanInfo.planGroup.clickBtnJump
          ? this.echoNovelPlanInfo.planGroup.clickBtnJump
          : 4
        // ! 回显"按钮文案”(公众号)
        //   this.radio7 = this.echoNovelPlanInfo.planGroup.buttonParam ?
        if (this.echoNovelPlanInfo.planGroup.buttonParam) {
          const buttonParam = this.echoNovelPlanInfo.planGroup.buttonParam
          switch (buttonParam) {
            case '关注公众号':
              this.radio7 = 1
              break
            case '立即咨询':
              this.radio7 = 2
              break
            case '立即申请':
              this.radio7 = 3
              break
            case '阅读小说':
              this.radio7 = 4
              break
          }
        }
      }

      // ! 回显公众号底部文案
      if (this.echoNovelPlanInfo.posType === 0) {
        this.channelBottomData = this.deepClone(
          this.echoNovelPlanInfo.planGroup
        )
      }
      // ! 回显 定向人群
      const directs = this.deepClone(this.echoNovelPlanInfo.directs)
      this.crowdInfoList = directs.map(item => {
        this.crowdPartNum++
        const { gender, minAge, maxAge, area, name } = item
        let copyGender = null
        const customGender = JSON.parse(gender)
        if (customGender.includes('MALE')) {
          copyGender = 2
        }
        if (customGender.includes('FEMALE')) {
          copyGender = 3
        }
        if (customGender.length === 0) {
          copyGender = 1
        }
        return {
          gender: copyGender,
          areaType: 'area',
          minAge: minAge,
          maxAge: maxAge,
          transferAreaData: JSON.parse(area),
          area: this.echoArea(JSON.parse(area)),
          unfold: true,
          isShowRegionList: false,
          customId: this.crowdPartNum,
          title: name,
          isDeleted: false, //! 是否被删除 回显第一步默认为false
          isChooseAll: false
        }
      })
      // 回显地域树形结构里面的内容
      this.$nextTick(() => {
        this.crowdInfoList.forEach((item, index) => {
          const area = item.area
          const ids = area.map(item => item.cid)
          this.$refs.regionTree[index].setCheckedKeys(ids)
        })
      })

      // 初始化年龄选择框的选项
      for (let i = 14; i <= 65; i++) {
        this.startAgeList.push({
          name: `${i}岁`,
          id: i
        })
        this.endAgeList.push({
          name: `${i}岁`,
          id: i
        })
        if (i === 65) {
          this.endAgeList = [...this.endAgeList, { name: '65岁以上', id: 66 }]
        }
      }

      // !回显罗卡定向
      const {
        education,
        os,
        devicePrice,
        deviceBrandModel,
        telcom,
        connection,
        inActionList,
        notInActionList
      } = this.echoNovelPlanInfo
      // 回显"学历"
      const copyEducation = this.deepClone(JSON.parse(education))
      if (copyEducation.length === 0) {
        this.radio9 = 1
      } else {
        this.radio9 = 2
        this.educationLevel = copyEducation
      }
      // 回显"操作系统"
      const copyOs = this.deepClone(JSON.parse(os))
      if (copyOs.length === 0) {
        this.radio10 = 1
      } else {
        this.radio10 = 2
        this.phoneType = copyOs
      }
      // 回显手机价格
      const copyDevicePrice = this.deepClone(JSON.parse(devicePrice))
      if (copyDevicePrice.length === 0) {
        this.radio11 = 1
      } else {
        this.radio11 = 2
        this.phonePrice = copyDevicePrice
      }
      // 回显手机品牌
      // * 默认每个手机品牌都没选中
      brandList.forEach(item => {
        item.checkStatus = false
      })
      const copyDeviceBrandModel = this.deepClone(JSON.parse(deviceBrandModel))
      if (deviceBrandModel.length === 0) {
        this.radio12 = 1
        this.brandList = this.deepClone(brandList)
      } else {
        if (copyDeviceBrandModel.some(item => item > 0)) {
          this.radio12 = 2
        }
        if (copyDeviceBrandModel.some(item => item < 0)) {
          this.radio12 = 3
        }
        this.alreadyChooseBrandList = brandList.filter(item =>
          copyDeviceBrandModel.some(id => Math.abs(id) === item.cid)
        )
        this.brandList = brandList.map(item => {
          if (
            this.alreadyChooseBrandList.some(brand => brand.cid === item.cid)
          ) {
            item.checkStatus = true
          }
          return item
        })
      }
      this.copyBrandList = this.deepClone(this.brandList)

      // 回显"运营商"
      const copyTelcom = this.deepClone(JSON.parse(telcom))
      if (copyTelcom.length === 0) {
        this.radio13 = 1
      } else {
        this.radio13 = 2
        this.telcom = copyTelcom
      }

      // 回显"联网方式"
      const copyConnection = this.deepClone(JSON.parse(connection))
      if (copyConnection.length === 0) {
        this.radio14 = 1
      } else {
        this.radio14 = 2
        this.connection = copyConnection
      }
      // 回显"再营销"
      const copyInActionList = this.deepClone(JSON.parse(inActionList))
      if (copyInActionList.length === 0) {
        this.radio16 = 1
      } else {
        this.radio16 = 2
        this.inActionList = copyInActionList
      }
      // 回显"排除营销"
      const copyNotInActionList = this.deepClone(JSON.parse(notInActionList))
      if (copyNotInActionList.length === 0) {
        this.radio17 = 1
      } else {
        this.radio17 = 2
        this.notInActionList = copyNotInActionList
      }
    },
    // !创建多个组件会遇到问题
    initRegionStyle (index) {
      this.$nextTick(() => {
        const wrapperList = [...document.querySelectorAll('.wrappers')]
        // const dom = wrapperList[index]
        for (let i = 0; i < wrapperList.length; i++) {
          const key = `region${i + 1}`
          if (!this[key]) {
            this[key] = new BScroll(wrapperList[i], {
              scrollY: true,
              click: true,
              scrollbar: {
                fade: false,
                interactive: true
              },
              bounce: {
                top: true,
                bottom: true,
                left: true,
                right: true
              },
              bounceTime: '2000',
              mouseWheel: true
            })
          } else {
            this[key].refresh()
          }
        }
      })
    },
    initChooseRegionStyle (index) {
      this.$nextTick(() => {
        const regionWrapperList = [
          ...document.querySelectorAll('.region-wrapper')
        ]
        for (let i = 0; i < regionWrapperList.length; i++) {
          const dom = regionWrapperList[i]
          const val = `regionWrapperList${i + 1}`
          if (!this[val]) {
            this[val] = new BScroll(dom, {
              scrollY: true,
              click: true,
              scrollbar: {
                fade: false,
                interactive: true
              },
              bounce: {
                top: true,
                bottom: true,
                left: true,
                right: true
              },
              bounceTime: '2000',
              mouseWheel: true
            })
          } else {
            this[val].refresh()
          }
        }
      })
    },
    initPhoneTreeStyle () {
      this.$nextTick(() => {
        const wrapper = document.querySelector('.wrapper')
        this.phoneScroll = new BScroll(wrapper, {
          scrollY: true,
          click: true,
          scrollbar: {
            fade: false,
            interactive: true
          },
          bounce: {
            top: true,
            bottom: true,
            left: true,
            right: true
          },
          bounceTime: '2000',
          mouseWheel: true
        })
      })
    },
    initBrandScroll () {
      this.$nextTick(() => {
        const wrapper = document.querySelector('.brand-wrapper')
        if (!this.brandScroll) {
          this.brandScroll = new BScroll(wrapper, {
            scrollY: true,
            click: true,
            scrollbar: {
              fade: false,
              interactive: true
            },
            bounce: {
              top: true,
              bottom: true,
              left: true,
              right: true
            },
            bounceTime: '2000',
            mouseWheel: true
          })
        } else {
          this.brandScroll.refresh()
        }
      })
    },
    showRegionList (crowd, index) {
      const flag = crowd.isShowRegionList && index + 1 === crowd.id
      if (flag) {
        this.initRegionStyle(index)
      }
      return flag
    },
    showChooseRegionList (crowd, index) {
      // const flag = crowd.area.length > 0 && index + 1 === crowd.id;
      // ;
      // if (flag) {
      //   this.initChooseRegionStyle(index);
      // }
    },
    createEditor (id) {
      const editor = new E(`#${id}`)
      editor.config.menus = ['emoticon']
      editor.config.height = 100
      editor.config.zIndex = 1
      editor.config.onchange = html => {
        this.editorChange(html, id)
      }
      editor.config.placeholder =
        '文案友好、易阅读和理解，建议与图片设计、详情页内容关联'
      // !初始化表情包
      const emojiList = this.emojiList.map(item => {
        return {
          alt: item.parentName,
          src: item.name
        }
      })
      editor.config.emotions = [
        {
          title: '默认',
          type: 'image',
          content: emojiList
        }
      ]
      editor.create()

      // this.desc1.push({
      //   id,
      //   text: []
      // });
      this.editorArrList.push(editor)
    },
    editorChange (html, id) {
      for (let i = 0; i < this.editorList.length; i++) {
        const editor = this.editorList[i]
        // const specialCharacterExp = /[\\<>\\[\]#^&+?*]/gm
        if (editor.id === id) {
          const textValue = html.replace(/<\/?.+?>/g, '')
          // .replace(/&lt;/g, "<")
          // .replace(/&gt;/g, ">");
          if (/(^&nbsp;)|(&nbsp;$)/g.test(textValue)) {
            this.showErrorMsg('广告文案行首或行尾不能包含空格，请删除!')
          }
          // 汉字字符长度为1，其余2个字符为1个长度
          const ChineseLen =
            (textValue.match(/[\u4e00-\u9fa5]/g)
              ? textValue.match(/[\u4e00-\u9fa5]/g).length
              : null) || 0
          // 中文标点符号需要算一个字符
          const isContainsChanesePunctuation = textValue.match(
            /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g
          )
          const ChinesePunctuationLen =
            (isContainsChanesePunctuation
              ? isContainsChanesePunctuation.length
              : null) || 0

          const WordLen = Math.ceil(
            (textValue.length - ChineseLen - ChinesePunctuationLen) / 2
          )
          const imgRegExp = /<img[^>]+>/g
          // const altRegExp = /<\[\[.*\]\]/g
          const emojiArr = html.match(imgRegExp)
          const eLen = emojiArr ? emojiArr.length : 0
          // const textLen = textValue.length + eLen
          const textLen = ChineseLen + WordLen + eLen + ChinesePunctuationLen
          if (emojiArr && emojiArr.length >= 5) {
            this.showErrorMsg('单个文案最多添加四个表情!')
          }
          editor.html = html
          editor.textLen = textLen
          editor.emojiLen = eLen
          editor.value = this.formateRichText(html)
          console.log(editor.value);
          return
        }
      }
    },
    choosePage () {
      this.isShowChoosePage = true
    },
    confirm (selectionList) {
      this.pageList = [...this.pageList, ...selectionList]
      this.isShowChoosePage = false
    },
    handleDelete (index) {
      this.pageList.splice(index, 1)
    },
    addEditor () {
      this.editorSuffix++
      let canCreateEditor = true
      const curEditor = this.editorList[this.editorList.length - 1]
      const { value, emojiLen, textLen } = curEditor

      if (!value) {
        this.showErrorMsg('请输入广告文案!')
        canCreateEditor = false
      }
      if (emojiLen > 4) {
        this.showErrorMsg('单个文案最多添加四个表情!')
        canCreateEditor = false
      }
      if (textLen > 40) {
        this.showErrorMsg('广告文案不得超过40字，请缩短输入内容!')
        canCreateEditor = false
      }
      if (canCreateEditor) {
        const id = `inputText-${this.editorSuffix}`
        this.editorList.push({
          id,
          html: '',
          value: '',
          emojiLen: 0,
          textLen: 0
        })
        this.$nextTick(() => {
          this.createEditor(id)
        })
      }
    },
    closeEditor (index) {
      if (this.editorList.length === 1) {
        this.showErrorMsg('至少保留一个广告文案!')
        return
      }
      this.editorList.splice(index, 1)
      this.editorArrList.splice(index, 1)
    },
    handleMenuSelect (index) {
      this.defaultActive = index
    },
    uploadAdImage (index) {
      // this.$refs.adImage[0].click()
      // ;
      this.$refs.adImage[index].click()
    },

    addImage () {
      const val = this.adImagePartList[this.adImagePartList.length - 1]
      const { imgUrl, title, firstDesc, secondDesc } = val
      if (!imgUrl) {
        this.showErrorMsg('请上传广告位图片!')
        return
      }
      if (!title) {
        this.showErrorMsg('请输入图文标题!')
        return
      }
      if (!firstDesc) {
        this.showErrorMsg('请输入首行文案!')
        return
      }
      if (!secondDesc) {
        this.showErrorMsg('请输入次行文案!')
        return
      }
      this.adImagePartList.push({
        imgUrl: '', // 图片线上链接
        title: '', // 图片标题
        firstDesc: '', // 首行文案
        secondDesc: '', // 次行文案
        thirdDesc: '', // 第三行文案
        unfold: true
      })
    },
    handleCheckChange (data, checkedNode, index) {
      // const nodeList = this.$refs.regionTree[index].getCheckedNodes() // 获取全部节点
      const nodeList = checkedNode.checkedNodes
      if (nodeList.length === 0) {
        this.crowdInfoList[index].area = []
        this.crowdInfoList[index].transferAreaData = []
        return
      }

      const customRegionArr = []
      const customRegion = {}

      let rootPrefix = nodeList[0].cid.toString().slice(0, 2)

      for (let i = 0; i < nodeList.length; i++) {
        const target = nodeList[i]
        const prefix = target.cid.toString().slice(0, 2)
        if (prefix === rootPrefix) {
          if (`${prefix}` in customRegion) {
            customRegion[`${prefix}`].push(target)
          } else {
            customRegion[`${prefix}`] = [target]
          }
        } else {
          rootPrefix = prefix
          customRegion[`${prefix}`] = [target]
        }
      }

      Object.keys(customRegion).forEach(item => {
        const val = customRegion[item]
        customRegionArr.push(val)
      })
      const arr = []
      const transferAreaData = []
      this.getCrowdRegion(customRegionArr, index, arr, transferAreaData)
      this.crowdInfoList[index].area = this.deepClone(arr)
      this.crowdInfoList[index].transferAreaData = this.deepClone(
        transferAreaData
      )
    },
    getCrowdRegion (customRegionArr, index, arr, transferAreaData) {
      // ! 需要对直辖市cid进行判断
      const specialCid = [500000, 110000, 120000, 310000]
      for (let i = 0; i < customRegionArr.length; i++) {
        const region = customRegionArr[i]
        for (let j = 0; j < region.length; j++) {
          const val = region[j]
          if ('children' in val) {
            arr.push({
              ...val,
              alias: val.name
            })
            if (specialCid.includes(val.cid)) {
              transferAreaData.push(val.cid)
            } else {
              const secondLevelRegion = val.children
              secondLevelRegion.forEach(item => {
                transferAreaData.push(item.cid)
              })
            }
            break
          } else {
            arr.push({
              ...val,
              alias: `${val.parentName}-${val.name}`
            })
            transferAreaData.push(val.cid)
          }
        }
      }
    },
    travelNode (nodeList, halfNodeList, rootPrefix, regionArr) {
      for (let i = 0; i < nodeList.length; i++) {
        const node = nodeList[i]
        const prefix = node.cid.toString().slice(0, 2)
        // ! 如果node有children选项 表示children下面全部被选中
        // ! 首先遍历的是最顶层的元素 用顶层元素一层一层往下遍历
        if ('children' in node) {
          // this.travelChildrenNode(node);
          const flag = this.checkRegionLevel(node.children, prefix)
          if (!flag) {
            regionArr.push(node)
          }
        } else {
          if (rootPrefix !== prefix) {
            // ! 如果没有children属性 则该元素为最底层的子元素 需要向上查找父级元素
            this.travelParentNode(node, nodeList, halfNodeList, regionArr)
          }
        }
      }
    },
    checkRegionLevel (nodeList, prefix) {
      for (let i = 0; i < nodeList.length; i++) {
        const subPreFix = nodeList[i].cid.toString().slice(0, 2)
        // !如果相等说明 是该层是子层
        if (subPreFix === prefix) {
          return false
        }
      }
      return true
    },
    travelChildrenNode (node) {
      for (let i = 0; i < node.children; i++) {
        const target = node[i]
        if ('children' in target) {
          return this.travelChildrenNode(target)
        }
      }
    },
    travelParentNode (node, nodeList, halfNodeList, regionArr) {
      // const parentName = node.parentName
      if (halfNodeList && halfNodeList.length > 0) {
        const parentId = node.parentId
        const parentNode = halfNodeList.find(
          halfNode => halfNode.cid === parentId
        )
        if (parentNode && parentNode.parentId !== 0) {
          for (let i = 0; i < parentNode.children.length; i++) {
            // ! 这个地方可能会被重复push
            const target = parentNode.children[i]
            // ! 如果能找到 说明 该地域被选中
            if (nodeList.find(n => n.cid === target.cid)) {
              regionArr.push(target)
            }
          }

          this.travelParentNode(parentNode, nodeList, halfNodeList, regionArr)
        }
      }
    },
    getParentName (node, halfNodeList) {
      const pName = node.parentName
      const pNode = halfNodeList.find(
        halfNode => halfNode.cid === node.parentId
      )
      if (!pNode || pNode.parentId === 0) {
        return ''
      } else {
        return `${this.getParentName(pNode, halfNodeList)}-${pName}`
      }
    },
    getRegions (data) {
      const arr = []
      if ('children' in data) {
        arr.push(data)
        return this.getRegions(data.children)
      }
      return arr
    },
    // 添加新一组定向人群信息
    addMoreCrowd () {
      const val = this.crowdInfoList[this.crowdInfoList.length - 1]
      const { minAge, maxAge, title, area } = val
      if (maxAge <= minAge) {
        this.showErrorMsg('最大年龄不能小于等于最小年龄，请重新选择!')
        return
      }
      if (!title) {
        this.showErrorMsg('请输入广告名称!')
        return
      }
      if (area.length === 0) {
        this.showErrorMsg('请对当前的定向人群选择地域!')
        return
      }
      this.crowdPartNum++
      this.crowdInfoList.push({
        ...JSON.parse(JSON.stringify(defaultInfo)),
        customId: this.crowdPartNum
      })
    },
    handleIconClick (crowd, index) {
      this.$nextTick(() => {
        this.$refs.crowdTitleInput[index].select()
      })
    },
    handleMarketingChange (val) {},
    deleteCrowdInfo (index) {
      // this.delCrowdInfoList(index);
      if (this.crowdInfoList.length === 1) {
        this.showErrorMsg('至少保留一个定向人群模块!')
        return
      }
      this.$confirm('删除之后将无法恢复，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.crowdInfoList[index].isDeleted = true
      })
    },
    handleBranchClick (p) {
      const phone = JSON.parse(JSON.stringify(p))
      phone.checkStatus = !phone.checkStatus
      const { cid } = phone
      const targetIndex = this.brandList.findIndex(brand => brand.cid === cid)
      if (targetIndex > -1) {
        this.$set(this.brandList, targetIndex, { ...phone })
        const index = this.copyBrandList.findIndex(brand => brand.cid === cid)
        this.copyBrandList[index].checkStatus = !this.copyBrandList[index]
          .checkStatus
        const key = this.alreadyChooseBrandList.findIndex(
          item => item.cid === cid
        )
        if (!phone.checkStatus) {
          if (key > -1) {
            this.isChooseAllBrand = false
            this.alreadyChooseBrandList.splice(key, 1)
          }
        } else {
          this.alreadyChooseBrandList.push(phone)
        }
      }
    },
    handleChooseAllBrand (type) {
      const copyBrandList = JSON.parse(JSON.stringify(this.brandList))
      // !如果点击清空 则直接赋值为false
      if (type === 'clear') {
        this.isShowBrandList = false
        this.isChooseAllBrand = false
      } else {
        this.isChooseAllBrand = !this.isChooseAllBrand
      }
      if (this.isChooseAllBrand) {
        copyBrandList.forEach(item => {
          item.checkStatus = true
        })
        this.copyBrandList.forEach(item => {
          item.checkStatus = true
        })
        this.alreadyChooseBrandList = JSON.parse(JSON.stringify(copyBrandList))
      } else {
        copyBrandList.forEach(item => {
          item.checkStatus = false
        })
        this.copyBrandList.forEach(item => {
          item.checkStatus = false
        })

        this.alreadyChooseBrandList = []
      }

      this.brandList = JSON.parse(JSON.stringify(copyBrandList))
    },
    deletePhoneItem (brand) {
      brand.checkStatus = false
      this.isChooseAllBrand = false
      const { cid } = brand
      const targetIndex = this.brandList.findIndex(item => item.cid === cid)
      this.$set(this.brandList, targetIndex, { ...brand })
      const index = this.alreadyChooseBrandList.findIndex(
        item => item.cid === cid
      )
      if (index > -1) {
        this.alreadyChooseBrandList.splice(index, 1)
      }
    },
    handleAdUploadImage (e, index) {
      const targetFile = e.target.files[0]
      if (targetFile.size > 2000 * 1024) {
        this.showErrorMsg('您上传的图片大小不符合要求,请重新上传!')
        this.$refs.adImage[index].value = ''
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(targetFile)
      fr.onload = event => {
        const image = new Image()
        image.src = event.currentTarget.result
        const src = image.src
        image.onload = () => {
          const { width, height } = image
          if (width < 960 || height < 540) {
            this.showErrorMsg('您上传的图片尺寸不符合要求,请重新上传!')
            this.$refs.adImage[index].value = ''
            return
          }
          if (width === 960 && height === 540) {
            uploadFile({ file: targetFile })
              .then(res => {
                this.adImagePartList[index].imgUrl = res
              })
              .finally(() => {
                this.$refs.adImage[index].value = ''
                this.resetCropArr()
              })
          } else {
            this.cropTypeArr = [{ width: 960, height: 540 }]
            this.trueWidth = width
            this.trueHeight = height
            this.needCropUrl = src
            this.needCropWidth = 960 / 2
            this.needCropHeight = 540 / 2
            this.myCropperWidth = 960
            this.myCropperHeight = 540

            this.fileName = targetFile.name

            this.cropperVisible = true
            this.cropTypeObj = {
              type: 'adImage',
              index
            }
          }
        }
      }
    },
    // 添加顶图
    addTopImages () {
      this.$refs.uploadTopImage.click()
    },
    //  删除顶图
    deleteTopImage (index) {
      this.$confirm('此操作将会永久删除文件，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButonText: '取消',
        type: 'warning'
      }).then(() => {
        this.topImageList.splice(index, 1)
        this.setTopImages(this.topImageList)
        this.setIsEditTopImages(true)
      })
    },
    // 上传顶图的回调
    async handleTopImageUpload (e) {
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0]
      if (targetFile.size > 2000 * 1024) {
        this.showErrorMsg('上传的图片太大，请重新上传!')
        this.$refs.uploadTopImage.value = ''
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(targetFile)
      fr.onload = event => {
        const image = new Image()
        image.src = event.currentTarget.result
        const src = image.src
        image.onload = () => {
          const { width, height } = image
          if (this.echoNovelPlanInfo.posType !== 999) {
            this.cropTypeArr = [
              {
                width: 800,
                height: 800
              },
              {
                width: 800,
                height: 640
              },
              {
                width: 800,
                height: 450
              },
              {
                width: 640,
                height: 800
              }
            ]

            // if (
            //   FitSizeList.some(item => item[0] === width && item[1] === height)
            // ) {
            //   this.topImageLoading = true
            //   uploadFile({ file: targetFile })
            //     .then(res => {
            //       this.topImageList.push(res)
            //     })
            //     .finally(() => {
            //       this.topImageLoading = false
            //       this.resetCropArr()
            //       this.$refs.uploadTopImage.value = ''
            //     })
            // } else {
            if (width < 640 || height < 450) {
              this.showErrorMsg('上传的图片尺寸不符合要求，请重新上传!')
              this.$refs.uploadTopImage.value = ''
              this.resetCropArr()
              return
            }
            if (width > 800 && height < 450) {
              this.showErrorMsg('上传的图片尺寸不符合要求，请重新上传!')
              this.$refs.uploadTopImage.value = ''
              this.resetCropArr()
              return
            }

            // 裁剪 800 * 640
            if (height > 640 && height < 800 && width >= 800) {
              this.cropTypeArr = [
                {
                  width: 800,
                  height: 640
                },
                {
                  width: 800,
                  height: 450
                }
              ]
            }
            // 裁剪800 * 450
            if (height > 450 && height < 640 && width >= 800) {
              this.cropTypeArr = [
                {
                  width: 800,
                  height: 450
                }
              ]
            }
            // 裁剪 640*800
            if (width > 640 && width < 800 && height < 800) {
              this.showErrorMsg('上传的图片尺寸不符合要求，请重新上传!')
              this.$refs.uploadTopImage.value = ''
              this.resetCropArr()
              return
            }
            if (width > 640 && width < 800 && height >= 800) {
              this.cropTypeArr = [
                {
                  width: 640,
                  height: 800
                }
              ]
            }
            if (width === 800 && height === 800) {
              this.cropTypeArr = [
                {
                  width: 800,
                  height: 800
                },
                {
                  width: 800,
                  height: 640
                },
                {
                  width: 800,
                  height: 450
                },
                {
                  width: 640,
                  height: 800
                }
              ]
            }
            if (width === 800 && height === 640) {
              this.cropTypeArr = [
                {
                  width: 800,
                  height: 640
                },
                {
                  width: 800,
                  height: 450
                }
              ]
            }
            if (width === 800 && height === 450) {
              this.cropTypeArr = [
                {
                  width: 800,
                  height: 450
                }
              ]
            }
            if (width === 640 && height === 800) {
              this.cropTypeArr = [
                {
                  width: 640,
                  height: 800
                }
              ]
            }
            // }
          } else {
            this.cropTypeArr = [
              {
                width: 800,
                height: 800
              }
            ]
            if (width < 800 || height < 800) {
              this.showErrorMsg('上传的图片尺寸不符合要求，请重新上传!')
              this.$refs.uploadTopImage.value = ''
              return
            }
          }
          this.cropTypeObj = {
            type: 'topImage',
            index: null
          }
          this.topImageLoading = true
          this.trueWidth = width
          this.trueHeight = height
          this.needCropUrl = src
          this.needCropWidth = this.cropTypeArr[0].width / 2
          this.needCropHeight = this.cropTypeArr[0].height / 2
          this.myCropperWidth = 800
          this.myCropperHeight = 800
          this.fileName = targetFile.name
          this.cropperVisible = true
        }
      }
    },
    uploadImageFile (fileList) {
      const arr = []
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i]
        arr.push(uploadFile({ file }))
      }
      return Promise.all(arr)
    },
    async getImageHeightAndWidth (targetFileList) {
      const arr = []
      function fileReader (file) {
        return new Promise(resolve => {
          const fr = new FileReader()
          fr.readAsDataURL(file)
          fr.onload = event => {
            const image = new Image()
            image.src = event.currentTarget.result
            image.onload = async () => {
              const { width, height } = image
              resolve({
                width,
                height
              })
            }
          }
        })
      }
      for (let i = 0; i < targetFileList.length; i++) {
        const file = targetFileList[i]
        // ! push Promise
        arr.push(fileReader(file))
      }
      return arr
    },
    getTimestamp (time) {
      return +new Date(time)
    },
    changePerTimeSet (col, row, targetIndex) {
      const hourItemList = [...document.querySelectorAll('.hour-item')]
      this.$set(
        this.timesetEctype,
        targetIndex,
        hourItemList[targetIndex].classList.contains('active-hour') ? 1 : 0
      )
    },
    changeTimeSet () {
      const startIndex =
        Number(this.dayStartTime.slice(0, 2)) * 2 +
        (Number(this.dayStartTime.slice(-2)) === 0 ? 0 : 1)
      const endIndex =
        Number(this.dayEndTime.slice(0, 2)) * 2 +
        (Number(this.dayEndTime.slice(-2)) === 0 ? 0 : 1) -
        1
      const copyTimeSet = this.deepClone(this.timeset)

      // *动态修改timeset(以周为单位)
      for (let j = 1; j <= this.WEEK_DAYS; j++) {
        // !选中的赋值为1
        copyTimeSet.fill(
          1,
          (j - 1) * this.TIME_INTERVAL + startIndex,
          endIndex + (j - 1) * this.TIME_INTERVAL + 1
        )
        // !未选中的赋值为0
        if (startIndex > 0) {
          copyTimeSet.fill(
            0,
            (j - 1) * this.TIME_INTERVAL,
            (j - 1) * this.TIME_INTERVAL + startIndex
          )
        }
        if (endIndex < 47) {
          copyTimeSet.fill(
            0,
            (j - 1) * this.TIME_INTERVAL + endIndex + 1,
            j * this.TIME_INTERVAL
          )
        }
      }
      this.timeset = this.deepClone(copyTimeSet)
    },
    handlePhoneInput (val) {
      if (!val) return
      this.brandList = this.copyBrandList.filter(item =>
        item.name.includes(val)
      )
    },
    handleTitle (col, row) {
      switch (row) {
        case 0:
          return `周一 ${this.timePeriod[col]}`
        case 1:
          return `周二 ${this.timePeriod[col]}`
        case 2:
          return `周三 ${this.timePeriod[col]}`
        case 3:
          return `周四 ${this.timePeriod[col]}`
        case 4:
          return `周五 ${this.timePeriod[col]}`
        case 5:
          return `周六 ${this.timePeriod[col]}`
        case 6:
          return `周日 ${this.timePeriod[col]}`
      }
    },
    // 回显自定义时段的dom样式
    echoCustomTimeDom (timeset) {
      timeset
        .split('')
        .map(item => Number(item))
        .forEach((item, index) => {
          if (item === 0) {
            const col =
              Math.ceil((index + 1) % this.TIME_INTERVAL) === 0
                ? 47
                : Math.ceil((index + 1) % this.TIME_INTERVAL) - 1
            const row = Math.ceil((index + 1) / this.TIME_INTERVAL) - 1
            this.changeCellStyle(col, row)
            this.changeDailyCellStyle(col, row)
          }
        })
    },
    // 回显地域信息
    echoArea (area) {
      const region = []
      for (let i = 0; i < this.regionData.length; i++) {
        const firstLevelCity = this.regionData[i]
        if (area.some(key => Number(key) === firstLevelCity.cid)) {
          region.push(firstLevelCity)
        }
        if ('children' in firstLevelCity) {
          const children = firstLevelCity.children
          const regionArr = []
          children.forEach(re => {
            if (area.some(key => Number(key) === re.cid)) {
              regionArr.push(re)
            }
          })
          // 判断回显数据的二级地域id是否和当前一级地域的children长度一样
          if (regionArr.length === children.length) {
            region.push(firstLevelCity)
          } else {
            region.push(...regionArr)
          }
        }
      }
      for (let i = 0; i < region.length; i++) {
        const target = region[i]
        if ('children' in target) {
          target.alias = target.name
        } else {
          target.alias = `${target.parentName}-${target.name}`
        }
      }
      return region
    },
    handleChannelBottomData (args) {
      if (args) {
        this.channelBottomData = this.deepClone({ ...args[0], ...args[1] })
        if ('radioText' in this.channelBottomData) {
          delete this.channelBottomData.radioText
        }
      }
    },
    nextStep () {
      const {
        pageList,
        // checkList,
        putSchedule,
        topImageList,
        timeset,
        timesetEctype,
        beginTime,
        chooseMethods,
        endTime,
        customSchedule,
        selectedList,
        editorList,
        crowdInfoList,
        adImagePartList,
        educationLevel,
        phoneType,
        phonePrice,
        alreadyChooseBrandList,
        telcom,
        connection,
        inActionList,
        notInActionList,
        radio1,
        radio2,
        radio5,
        radio12,
        echoNovelPlanInfo,
        channelBottomData
      } = this
      // !判断投放时间
      if (putSchedule === 1) {
        const startTimeStamp = this.getTimestamp(this.beginTime)
        const endTimeStamp = this.getTimestamp(this.endTime)
        if (startTimeStamp > endTimeStamp) {
          this.showErrorMsg('投放日期的结束时间不能小于结束时间!')
          this.scrollTarget('novel-plan-put-time')
          return
        }
      }
      if (customSchedule === 3 && this.totalPutTime === 0) {
        this.showErrorMsg('自定义时间段不能为空!')
        this.scrollTarget('novel-plan-put-time')
        return
      }
      // !判断推广页
      if (pageList.length === 0 && radio5 !== 6) {
        this.showErrorMsg('请选择推广页!')
        this.scrollTarget('novel-plan-page')
        return
      }

      // !判断是否设置顶图
      if (topImageList.length === 0) {
        this.showErrorMsg('请设置顶图!')
        this.scrollTarget('novel-plan-setting-top')
        return
      }
      if (topImageList.length > 6) {
        this.showErrorMsg('单次最多设置六张地顶图！')
        this.scrollTarget('novel-plan-setting-top')
        return
      }

      // !朋友圈信息流相关的判断
      if (echoNovelPlanInfo.posType === 999) {
        for (let i = 0; i < editorList.length; i++) {
          const editor = editorList[i]

          if (!editor.value) {
            this.showErrorMsg('请输入广告文案!')
            this.scrollTarget(editor.id)
            return
          }
          if (editor.eLen > 4) {
            this.showErrorMsg('单个文案最多添加四个表情!')
            this.scrollTarget(editor.id)
            return
          }
          if (editor.textLen > 40) {
            this.showErrorMsg('广告文案不得超过40字，请缩短输入内容!')
            this.scrollTarget(editor.id)
            return
          }
        }
      }
      // !优雅横版大图信息流相关的判断
      if (echoNovelPlanInfo.posType === 101) {
        for (let i = 0; i < adImagePartList.length; i++) {
          const val = adImagePartList[i]
          const { firstDesc, secondDesc, imgUrl, title } = val
          if (!imgUrl) {
            this.showErrorMsg('请上传广告位图片')
            this.scrollTarget('novel-plan-ad-setting')
            return
          }
          if (!firstDesc) {
            this.showErrorMsg('请输入首行文案!')
            this.scrollTarget('novel-plan-ad-setting')
            return
          }
          if (!secondDesc) {
            this.showErrorMsg('请输入次行文案!')
            this.scrollTarget('novel-plan-ad-setting')
            return
          }
          if (!title) {
            this.showErrorMsg('请输入广告名称!')
            this.scrollTarget('novel-plan-ad-setting')
            return
          }
        }
      }
      // !公众号底部文案信息流相关的判断
      if (echoNovelPlanInfo.posType === 0) {
        const { imgUrl, headDescType, headDescription } = channelBottomData
        if (imgUrl.length === 0) {
          this.showErrorMsg('请上传广告位图片!')
          this.scrollTarget('novel-plan-ad-setting')
          return
        }
        if (headDescType === 2 && !headDescription) {
          this.showErrorMsg('请输入信息栏文案!')
          this.scrollTarget('novel-plan-ad-setting')
          return
        }
      }

      // 判断是否选择地域
      for (let i = 0; i < crowdInfoList.length; i++) {
        const crowd = crowdInfoList[i]
        if (crowd.area.length === 0) {
          this.showErrorMsg('请选择地域!')
          this.scrollTarget('novel-plan-directional-crowd')
          return
        }
        if (!crowd.title) {
          this.showErrorMsg('请输入广告名称!')
          this.scrollTarget('novel-plan-directional-crowd')
          return
        }
        if (crowd.maxAge < crowd.minAge) {
          this.showErrorMsg('最大年龄不能小于等于最小年龄，请重新选择!')
          this.scrollTarget('novel-plan-directional-crowd')
          return
        }
      }
      let planGroup = {}
      if (echoNovelPlanInfo.posType === 999) {
        const echoPlanGroup = this.echoNovelPlanInfo.planGroup
        const arr = editorList.map(item => item.value)
        const descRichText = editorList.map(item => `${item.html}`)
        this.planGroupTypeOne.desc = arr
        this.planGroupTypeOne.descRichText = descRichText
        this.planGroupTypeOne.composePicList = echoPlanGroup.composePicList

        planGroup = this.deepClone(this.planGroupTypeOne)
      }
      if (echoNovelPlanInfo.posType === 101) {
        const arr = []
        adImagePartList.forEach(item => {
          const { imgUrl, title, firstDesc, secondDesc, thirdDesc } = item
          arr.push({
            imgUrl,
            title,
            firstDesc,
            secondDesc,
            thirdDesc
          })
        })
        this.planGroupTypeTwo.mpTagType = this.radio4
        this.planGroupTypeTwo.clickBtnJump = this.radio6
        this.planGroupTypeTwo.clickJump = this.radio5
        this.planGroupTypeTwo.desc = arr
        planGroup = this.deepClone(this.planGroupTypeTwo)
      }
      // 公众号底部文案
      if (echoNovelPlanInfo.posType === 0) {
        const {
          imgUrl,
          headDescType,
          headDescription,
          mpTagType,
          buttonParam,
          clickJump,
          clickBtnJump
        } = channelBottomData
        const obj = {
          desc: imgUrl,
          mpTagType,
          buttonParam,
          clickJump,
          clickBtnJump,
          headDescType,
          headDescription: headDescType === 1 ? '' : headDescription
        }
        planGroup = this.deepClone(obj)
      }
      // 构造定向人群结构
      const array = []
      const copyCrowdInfoList = this.deepClone(crowdInfoList).filter(
        item => !item.isDeleted
      )
      copyCrowdInfoList.forEach(item => {
        const obj = {}
        const { minAge, maxAge, gender, transferAreaData, title } = item
        // const areaArr = this.deepClone(area).map(item => `${item.cid}`)
        const areaArr = this.deepClone(transferAreaData).map(item => `${item}`)
        const jsonArea = this.toJsonString(areaArr)
        obj.gender =
          gender === 1
            ? this.toJsonString([])
            : gender === 2
              ? this.toJsonString(['MALE'])
              : this.toJsonString(['FEMALE'])
        obj.minAge = minAge
        obj.maxAge = maxAge
        obj.area_type = 'area'
        obj.name = `${title}`
        obj.area = jsonArea
        array.push(obj)
      })
      // 构造手机品牌
      let deviceBrandModel = []
      if (radio12 === 2) {
        deviceBrandModel = alreadyChooseBrandList.map(item => item.cid)
      }
      if (radio12 === 3) {
        deviceBrandModel = alreadyChooseBrandList.map(item =>
          Number(`-${item.cid}`)
        )
      }
      const params = {
        ...this.deepClone(this.echoNovelPlanInfo),
        editorList,
        // 应用模式
        posType: echoNovelPlanInfo.posType,
        // 投放时间相关
        timesetType: customSchedule,
        selectedList,
        timeset:
          customSchedule === 1
            ? ''
            : customSchedule === 3
              ? timesetEctype.join('')
              : timeset.join(''),
        beginTime: this.getTimestamp(beginTime),
        // 获取当天最后一秒的时间戳
        endTime:
          putSchedule === 1
            ? this.getTimestamp(endTime) + 1000 * 60 * 60 * 24 - 1
            : 0,

        // 出价方式相关
        bidActionType: chooseMethods,
        bidStrategy: radio1,
        bidObjective: radio2,
        // 推广页相关
        pageIds: pageList.map(item => item.id),
        // 顶图相关
        // topImgs: topImageList,
        planTopImgs: topImageList,

        // 外层广告设置相关
        planGroup,
        // 定向人群相关
        directs: array,

        // 罗卡定向相关
        education:
          this.radio9 === 1
            ? this.toJsonString([])
            : this.toJsonString(educationLevel),
        os:
          this.radio10 === 1
            ? this.toJsonString([])
            : this.toJsonString(phoneType),
        devicePrice:
          this.radio11 === 1
            ? this.toJsonString([])
            : this.toJsonString(phonePrice),
        deviceBrandModel:
          this.radio12 === 1
            ? this.toJsonString([])
            : this.toJsonString(deviceBrandModel),
        telcom:
          this.radio13 === 1
            ? this.toJsonString([])
            : this.toJsonString(telcom),
        connection:
          this.radio14 === 1
            ? this.toJsonString([])
            : this.toJsonString(connection),
        inActionList:
          this.radio16 === 1
            ? this.toJsonString([])
            : this.toJsonString(inActionList),
        notInActionList:
          this.radio17 === 1
            ? this.toJsonString([])
            : this.toJsonString(notInActionList)
      }
      // this.setEditor
      this.setEchoNovelPlanInfo(params)

      this.$emit('update:curStep', 2)
    },

    // 提取html格式文本
    formateRichText (str) {
      // const str2 = str.split('</p>')
      // let res = ''
      // const size = str2.length - 1
      // for (let i = 0; i <= size; i++) {
      //   const textExp = '</?[p|span].*?>'
      //   // const imgExp = '<img.*?alt="\\[(\\[.*?])]">'
      //   const imgExp = '<img.*?alt=(['|"])\[(.*?)\]\1\/>'
      //   let child = str2[i]
      //     .replace(new RegExp(textExp, 'gm'), '')
      //     .replace(new RegExp(imgExp, 'gm'), '$1')
      //     .replace(/<br>/g, '')
      //   if (i !== size) {
      //     child += '\n'
      //   }
      //   res += child
      // }
      // return res
      return str.replace(/<\/?[p|span].*?>/gmi,'') // 去除span p 标签
                .replace(/<img.*?alt=(['|"])\[(.*?)\]\1\/>/gmi,'$2') // 提取 img alt属性里面的内容
                .replace(/<br\/>/gmi,'') //取消<br\>换行标签
    },
    // 回显格式文本为带有html标签的格式
    echoHtml (val) {
      const reg = /\[[\u4e00-\u9fa5]+\]/gm
      return val.replace(reg, item => {
        return `<img class='eleImg' src=${
          this.matchEmojiUrl(item).name
        } alt="[${item}]"></img>`
      })
    },
    // 回显文本内容
    echoEditorItem () {
      for (let i = 0; i < this.editorList.length; i++) {
        const obj = this.editorList[i]
        const editor = new E(`#${obj.id}`)
        editor.config.menus = ['emoticon']
        editor.config.height = 100
        editor.config.zIndex = 1
        editor.config.showFullScreen = false
        editor.config.onchange = html => {
          this.editorChange(html, obj.id)
        }
        editor.config.placeholder =
          '文案友好、易阅读和理解，建议与图片设计、详情页内容关联'
        // !初始化表情包
        const emojiList = this.emojiList.map(item => {
          return {
            alt: item.parentName,
            src: item.name
          }
        })
        editor.config.emotions = [
          {
            title: '默认',
            type: 'image',
            content: emojiList
          }
        ]
        editor.create()
        editor.txt.html(obj.html)
      }
    },
    matchEmojiUrl (item) {
      return this.emojiList.find(emoji => emoji.parentName === item)
    },
    // 清空已选择的地区
    clearChooseRegion (index) {
      this.crowdInfoList[index].area = []
      this.$refs.regionTree[index].setCheckedKeys([])
    },
    // 取消单个已选择的地区
    deleteRegionItem (region, index) {
      this.$nextTick(() => {
        this.$refs.regionTree[index].setChecked(region.cid, false, true)
        const targetIndex = this.crowdInfoList[index].area.findIndex(
          item => item.cid === region.cid
        )
        if (targetIndex > -1) {
          this.crowdInfoList[index].area.splice(targetIndex, 1)
        }
      })
    },
    isGetCrowdPartStyle (crowd) {
      if (crowd.isDeleted) {
        return {
          padding: '0px !important'
        }
      }
    },
    hiddenTargetDom (e) {
      // ! 如果点击crowd-caontainer里面的元素 则隐藏对应部分的地域选择器
      const targetDom = [...document.querySelectorAll('.crowd-container')]
      for (let i = 0; i < targetDom.length; i++) {
        const dom = targetDom[i]
        if (dom.contains(e.target)) {
          this.crowdInfoList[i].isShowRegionList = false
          return
        }
      }
      // ! 如果点击其它模块，则隐藏全部地域选择器
      for (const idName of this.idNameList) {
        if (idName !== 'novel-plan-directional-crowd') {
          const dom = document.querySelector(`#${idName}`)
          if (dom.contains(e.target)) {
            this.crowdInfoList.forEach(item => {
              item.isShowRegionList = false
            })
            return
          }
        }
      }
    },
    // 删除广告位图片
    delAdImage (index) {
      this.adImagePartList[index].imgUrl = ''
    },
    // 删除单个广告位设置
    delAdItem (index) {
      if (this.adImagePartList.length === 1) {
        this.showErrorMsg('至少保留一个广告设置!')
        return
      }
      this.adImagePartList.splice(index, 1)
    },
    handleRegionFocus (index) {
      this.crowdInfoList[index].isShowRegionList = true
    },
    addBatchRegion (index) {
      this.regionIndex = index
      this.isShowRegionHistory = true
    },
    handleRegionSuccess (ids) {
      this.$refs.regionTree[this.regionIndex].setCheckedKeys(ids)
      const regionList = this.$refs.regionTree[
        this.regionIndex
      ].getCheckedNodes()
      this.handleCheckChange(
        [],
        { checkedNodes: regionList },
        this.regionIndex
      )
    },
    // 地域是否全选
    regionChooseAll (index) {
      this.crowdInfoList[index].isChooseAll = !this.crowdInfoList[index]
        .isChooseAll
      if (this.crowdInfoList[index].isChooseAll) {
        const ids = this.regionData.map(item => item.cid)
        this.$refs.regionTree[index].setCheckedKeys(ids)
        const regionList = this.$refs.regionTree[index].getCheckedNodes()
        this.handleCheckChange([], { checkedNodes: regionList }, index)
      } else {
        this.$refs.regionTree[index].setCheckedKeys([])
        this.crowdInfoList[index].area = []
      }
    },
    cropSuccess (file) {
      uploadFile({ file })
        .then(res => {
          // 优雅横版大图 广告位图片
          if (this.cropTypeObj.type === 'adImage') {
            this.adImagePartList[this.cropTypeObj.index].imgUrl = res
            this.$refs.adImage[this.cropTypeObj.index].value = ''
          }
          // 顶图
          if (this.cropTypeObj.type === 'topImage') {
            this.topImageList.push({
              topImg: res
              // initWidth:this.trueWidth,
              // initHeight:this.trueHeight,
            })
            this.topImageLoading = false
            this.$refs.uploadTopImage.value = ''
          }
        })
        .catch(err => {
          this.topImageLoading = false
          this.showErrorMsg(err)
        })
    },
    handleEchoWHObj (obj) {
      const { initWidth, initHeight } = obj
      this.topImageList[this.topImageList.length - 1].initWidth = initWidth
      this.topImageList[this.topImageList.length - 1].initHeight = initHeight
    },
    handleCropperClose () {
      if (this.cropTypeObj.index !== null) {
        this.$refs.adImage[this.cropTypeObj.index].value = ''
      }
      this.$refs.uploadTopImage.value = ''
      this.cropperVisible = false
      this.topImageLoading = false
    },
    // 显示错误信息
    showErrorMsg (msg, type = 'error') {
      this.$message({
        showClose: true,
        message: msg,
        type
      })
    },
    resetCropArr () {
      this.cropTypeArr = []
    },
    resetData () {
      this.planGroupTypeOne = {
        linkName: '了解公众号',
        linkPageType: 6, // 默认公众号详情
        snsDynamicShowType: 0, // 数据类型
        snsDynamicShowText: '', // 转化行为
        desc: [] // 文案
      }
      this.planGroupTypeTwo = {
        mpTagType: 0, // 标签展示(默认不启用)
        buttonParam: '关注公众号', // 按钮文案 (默认关注公众号)
        clickJump: 4, // 点击跳转 4 原生推广页 6 公众号详情页
        clickBtnJump: 0, // 0 原生推广页 40024 一键关注
        desc: []
      }
      this.adImagePartList = [
        {
          imgUrl: '', // 图片线上链接
          title: '', // 图片标题
          firstDesc: '', // 首行文案
          secondDesc: '', // 次行文案
          thirdDesc: '', // 第三行文案
          unfold: true
        }
      ]
      this.editorSuffix = 1
      this.editorList = [
        {
          id: 'inputText-1',
          html: '', // html格式的数据
          value: '', // 去除html标签之后的数据
          emojiLen: 0, // 表情个数
          textLen: 0 // 文字长度
        }
      ]
      this.$nextTick(() => {
        const id = this.editorList[this.editorList.length - 1].id
        this.createEditor(id)
      })
    },
    // 跳转到制定位置
    scrollTarget (id) {
      document.querySelector(`#${id}`).scrollIntoView({
        behavior: 'smooth'
      })
    },
    filterNode (val, data) {
      if (!val) {
        return true
      }
      return data.name.includes(val)
    },
    toJsonString (str) {
      return JSON.stringify(str)
    },
    deepClone (obj) {
      return JSON.parse(JSON.stringify(obj))
    }
  },
  watch: {
    switchValue: {
      handler (newV) {
        if (!newV) {
          this.radio3 = 0
        }
      }
    },
    customSchedule: {
      handler (newV) {
        if (newV === 2) {
          this.changeTimeSet()
        } else if (newV === 1) {
          this.timeset = Array.from({ length: 48 * 7 }, () => 1)
        }
      }
    },
    crowdText: {
      handler (newV, oldV) {
        let targetIndex = null
        // 用oldV进行循环 因为如果新增了 则newV的长度 > oldV的长度
        for (let i = 0; i < oldV.length; i++) {
          const item = oldV[i]
          if (item && item !== newV[i]) {
            targetIndex = i
            this.$refs.regionTree[targetIndex].filter(newV[targetIndex])
            break
          }
        }
      }
    },
    crowdInfoList: {
      handler (newV) {
        if (newV && Array.isArray(newV)) {
          newV.forEach((item, index) => {
            if (item.isShowRegionList) {
              this.initRegionStyle(index)
            }
            if (item.area.length > 0) {
              this.initChooseRegionStyle()
            }
          })
        }
      },
      deep: true
    },
    isShowBrandList: {
      handler (newV) {
        if (newV) {
          this.initPhoneTreeStyle()
        }
      }
    },
    endTime: {
      handler (newV) {
        // 如果是朋友圈模式
        const startTimestamp = this.getTimestamp(this.beginTime)
        // 这里获取的时间是当前的0点 所以进行比较需要转化为当天的23:59:59
        const endTimeStamp = this.getTimestamp(newV) + 1000 * 60 * 60 * 24 - 1
        const differTimeStamp = endTimeStamp - startTimestamp
        if (this.echoNovelPlanInfo.posType === 999) {
          // 如果是选择开始时间和结束时间
          if (this.putSchedule === 1) {
            if (differTimeStamp > 1000 * 60 * 60 * 24 * 60) {
              this.showErrorMsg(
                '朋友圈竞价广告时间跨度应介于6小时到60天之间，请检查时间参数'
              )
            }
            return
          }
        }
        // 如果是公众号或者激励模式
        if (
          this.echoNovelPlanInfo.posType === 101 ||
          this.echoNovelPlanInfo.posType === 8
        ) {
          if (this.putSchedule === 1) {
            if (differTimeStamp > 1000 * 60 * 60 * 24 * 365) {
              this.showErrorMsg('公众号时间跨度太大，请检查时间参数')
            }
          }
        }
      }
    },
    // 监听每日投放时段的开始时间
    dayStartTime: {
      handler (newV) {
        this.changeTimeSet()
      }
    },
    // 监听每日投放时段的结束时间
    dayEndTime: {
      handler (newV) {
        this.changeTimeSet()
      }
    },
    timeset: {
      handler (newV) {}
    },
    timesetEctype: {
      handler (newV) {
        if (newV) {
          let sum = 0
          newV.forEach(item => {
            if (item === 1) {
              sum += 0.5
            }
          })
          this.totalPutTime = sum
        }
        //   immediate: true
      }
    },
    // 监听目标转化量
    radio3: {
      handler (newV) {
        this.$set(this.planGroupTypeOne, 'snsDynamicShowType', newV)
        if (newV === 0) {
          this.$set(this.planGroupTypeOne, 'snsDynamicShowText', '')
        } else {
          this.$set(this.planGroupTypeOne, 'snsDynamicShowText', '关注')
        }
      }
    },
    radio7: {
      handler (newV) {
        if (newV === 1) {
          this.planGroupTypeTwo.buttonParam = '关注公众号'
        }
        if (newV === 2) {
          this.planGroupTypeTwo.buttonParam = '立即咨询'
        }
        if (newV === 3) {
          this.planGroupTypeTwo.buttonParam = '立即申请'
        }
        if (newV === 4) {
          this.planGroupTypeTwo.buttonParam = '阅读小说'
        }
      }
    },

    // 检测已选择的手机品牌
    alreadyChooseBrandList: {
      handler (newV) {
        // 如果是定向选择
        if (newV.length > 0) {
          this.initBrandScroll()
        }
      }
    },
    // 监听选择手机输入框内容的变化
    phoneBrandValue: {
      handler (newV) {
        if (!newV) {
          this.brandList = this.deepClone(this.copyBrandList)
          this.isShowChooseAllButton = true
          return
        }
        // 如果进行了筛选则不显示全选按钮
        this.isShowChooseAllButton = false
        this.brandList = this.copyBrandList.filter(brand =>
          brand.name.includes(newV)
        )
      }
    }
  },
  components: {
    choosePage,
    batchAddRegionHistory,
    echoChannelBottom,
    cropperModel,
    backUpDown
  }
}
</script>

<style lang="scss" scoped>
.icon-xuanze2-copy {
  color: #00bf8a;
}
.w150 {
  width: 150px !important;
}
.white-background {
  background: #fff;
}
.text-demo-item {
  border: 1px solid #ccc;
}
.highlight {
  font-weight: bold;
}
.editor {
  width: 260px;
  margin-bottom: 20px;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color-grey {
  background: #f3f3f3;
}
.cell-hover {
  background: #bae6ba !important;
}
/deep/ .eleImg {
  width: 24px !important;
  height: 20px !important;
}
/deep/ .w-e-panel-tab-content {
  height: 150px !important;
}
.region-choose-all {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 5px 22px;
  i {
    font-size: 15px;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
    position: relative;
    top: 1px;
    color: #808080;
  }
}
.showWarning {
  box-sizing: border-box;
  border: 1px solid #d9514c;
  box-shadow: 0 0 0 0.5px #d9514c, 0 0 0 4px hsla(0, 0%, 100%, 0.75),
    0 0 0 4px #d9514c;
}
.part {
  // margin-top: 10px;

  margin-bottom: 16px;
  background: #fff;
  padding: 30px 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 0px #e0e6ed;
  > div:not(:nth-of-type(1)) {
    padding-top: 20px;
  }
  .part-plan-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    font-weight: bold;
  }
}
/deep/ .el-menu {
  width: auto !important;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
/deep/ .el-collapse-item__header {
  background: inherit;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

.top-image-tips {
  font-size: 15px;
  color: #99a9bf;
  > p {
    margin: 5px 0;
  }
}

.custom-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  outline: none;
  width: 177px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-upload-input {
  }
}

.image-part {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column !important;
  .image-list {
    width: 100%;
    background: #f8f8f8;
    padding: 20px;
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 10px 0;
      span {
        width: 100px;
      }
      > div {
        // margin:10px 0;
      }
    }
  }
}
.el-radio {
  margin-bottom: 20px;
}
.selected-hover {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0 0 0 2px #ff6666 !important;
  }
}
.add-more {
  height: 36px;
  line-height: 36px;
  padding: 5px 13px;
  cursor: pointer;
  &:hover {
    background: #e7f9ef;
  }
}

.main-container {
  flex-grow: 1;
  margin: 0 10px 10px 16px;
  // scroll-behavior: smooth !important;
  .novel-plan-purchase-method {
    background: #fff;
    box-sizing: border-box;
    padding: 15px 20px 35px 20px;
    width: 100%;
    font-size: 14px;
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    .highlight {
      width: 150px;
    }
    .tips {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > div {
        > p:nth-of-type(1) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .novel-plan-put-time {
    // margin-top: 10px;
    background: #fff;
    padding: 30px 20px;
    > div {
      padding-top: 20px;
      > div {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span:nth-of-type(1) {
          width: 150px;
          align-self: flex-start;
        }
        > span:nth-of-type(2) {
          width: 20px;
          text-align: center;
        }
        .selected-schedule-list {
          width: 100%;
          .selected-item {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            margin-right: 12px;
            margin-bottom: 12px;
            padding-left: 15px;
            padding-right: 15px;
            height: 26px;
            font-size: 13px;
            font-weight: 400;
            line-height: 26px;
            color: #1f1f1f;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 0 0 1px hsla(0, 0%, 64%, 0.25);
            transition: all 0.15s ease;
            cursor: default;
            animation: highlight-2fdqKiKos2 1.2s ease both;
            .el-icon-error {
              position: absolute;
              top: -5px;
              left: 96%;
              color: #ff6666;
              background: #fff;
              z-index: 999;
            }
          }
        }
        .custom-time-area {
          // min-width: 1238px;
          width: 100%;
          .sumTime {
            text-align: right;
            color: #a3a3a3;
          }
          .time-block {
            width: 100%;
            margin-top: 10px;
            .time-header {
              width: 100%;
              display: flex;
              margin-bottom: -1px;
              .time-info {
                margin: 0 -1px -1px 0;
                width: 46px;
                height: 48px;
                font-size: 12px;
                line-height: 48px;
                background: #fff;
                border: 1px solid #ebebeb;
                border-top-left-radius: 4px;
                color: #8c8c8c;
                letter-spacing: 0;
                text-align: center;
              }
              .time-range-block {
                flex: 1;
                .time-range-block-list {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  > p {
                    margin: 0 -1px -1px 0;
                    height: 26px;
                    font-size: 12px;
                    line-height: 26px;
                    background: #fff;
                    border: 1px solid #ebebeb;
                    color: #8c8c8c;
                    letter-spacing: 0;
                    text-align: center;
                  }
                }
                .time-hours {
                  display: grid;
                  grid-template-columns: repeat(24, 1fr);
                  .hour-num {
                    margin: 0 -1px -1px 0;
                    height: 22px;
                    font-size: 12px;
                    line-height: 22px;
                    letter-spacing: 0;
                    color: #8c8c8c;
                    text-align: center;
                    background: #fff;
                    border: 1px solid #ebebeb;
                  }
                }
              }
            }
            .time-body {
              display: flex;
              width: 100%;
              > ul:nth-of-type(1) {
                .day-item {
                  margin: 0 -1px -1px 0;
                  width: 46px;
                  height: 24px;
                  font-size: 12px;
                  color: #8c8c8c;
                  letter-spacing: 0;
                  text-align: center;
                  line-height: 24px;
                  background: #fff;
                  border: 1px solid #ebebeb;
                }
              }
              .time-item-block {
                flex: 1;
                display: grid;
                grid-template-columns: repeat(48, 1fr);
                .hour-item {
                  margin: 0 -1px -1px 0;
                  height: 24px;
                  background: #fff;
                  border: 1px solid #ebebeb;
                  cursor: pointer;
                }
                .active-hour {
                  background: #ddf3dd;
                  border: 1px solid #07c160 !important;
                  z-index: 2;
                }
              }
            }
          }
          .daily-block {
            margin-top: 8px;
            display: -ms-flexbox;
            display: flex;
            .daily-title {
              width: 46px;
              height: 32px;
              font-size: 12px;
              font-weight: 700;
              color: #6b6b6b;
              letter-spacing: 0;
              text-align: center;
              line-height: 32px;
              background: #fff;
              border: 1px solid #ebebeb;
              border-radius: 4px 0 0 4px;
              margin-right: -1px;
            }
            .daily-items {
              flex: 1;
              display: grid;
              grid-template-columns: repeat(48, 1fr);
              .daily-item {
                height: 32px;
                background: #fff;
                border: 1px solid #ebebeb;
                margin-right: -1px;
              }
              .selected-daily-item {
                background: #ddf3dd;
                border: 1px solid #07c160 !important;
                z-index: 2;
              }
            }
          }
        }
      }
    }
  }
  .novel-plan-bidding-method {
    // margin-top: 10px;
    background: #fff;
    padding: 30px 20px;
    > div {
      padding-top: 20px;
      .methods {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        cursor: pointer;
        > span {
          color: #5a5a5a;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 200px;
          padding: 6px;
        }
      }
      .put-type {
        margin-bottom: 30px;
        > span {
          min-width: 150px;
          align-self: flex-start;
        }
      }
      .put-target {
        margin-bottom: 30px;
        > span {
          min-width: 150px;
          align-self: flex-start;
        }
      }
    }
  }
  .novel-plan-page {
    // margin-top: 10px;
    background: #fff;
    padding: 30px 20px;
    > div {
      // padding-top: 20px;
      .page-list {
        margin-top: 20px;
        .page-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top: 1px solid #f5f5f5;
          padding: 10px;
          > span {
            width: 33%;
            text-align: center;
          }
          &:nth-last-of-type(1) {
            border-bottom: 1px solid #f5f5f5;
          }
        }
        .page-header {
          color: #8d8d8d;
        }
      }
    }
  }
  .novel-plan-setting-top {
    .tips {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border: 1px solid #fab427;
      background: #fffbe6;
      box-sizing: border-box;
      padding: 15px 20px;
      border-radius: 4px;
      i {
        color: #fab427;
        font-size: 24px;
      }
      > div {
        margin-left: 10px;
        font-size: 16px;
      }
    }
    .show-top-images {
      display: flex;
      justify-items: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;
      > div {
        position: relative;
        display: inline-block;
        width: 160px;
        // height:160px;
        height: 160px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          // object-fit: ;
          object-fit: cover;
        }
        .mask-hover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba($color: #000000, $alpha: 0.5);
          display: none;
          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
        &:hover {
          .mask-hover {
            display: block;
          }
        }
      }
    }
  }
  .novel-plan-ad-setting {
    /deep/ .el-icon-arrow-right {
      display: none;
    }
    .ad-image {
      background: #fdfdfd;
      margin-bottom: 5px;
      box-sizing: border-box;
      // padding: 0 20px 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      > span {
        align-self: flex-start;

        width: 100px !important;
      }
      .ad-image-container {
        flex-grow: 1;
      }
      .operation {
        margin-left: auto;
        > span {
          color: #3facdb;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .ad-setting-menu {
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      .menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        font-size: 16px;
        line-height: 50px;

        & > span:nth-of-type(1) {
          padding: 0 20px;
        }
        & > span:nth-of-type(2) {
          height: 2px;
          width: 100%;
          border-radius: 4px;
        }
        .is-active {
          background: #00bf8a !important;
        }
      }
      .is-disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }
    .menu-container {
      > div {
        margin-top: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span:nth-of-type(1) {
          width: 100px;
        }
        .text-editor-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
          .editor-container {
            margin-right: 20px;
            margin-bottom: 20px;
            position: relative;
            > p {
              margin-bottom: 20px;
            }
            > p:nth-of-type(1) {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .limit-statistics {
              color: #ccc;
              position: absolute;
              top: 25%;
              left: 69%;
              width: 80px;
              z-index: 2;
            }
          }
        }

        .ad-upload-image {
          > div {
            margin-top: 10px;
          }
          .upload-image {
            position: relative;
            width: 177px;
            height: 100px;
            .mask {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.3);
              justify-content: center;
              align-items: center;
              display: none;
              i {
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            img {
              width: 100%;
              height: 100%;
            }
            &:hover .mask {
              display: block;
            }
          }
        }
      }
      .fx-start {
        > span {
          align-self: flex-start;
        }
      }
    }
  }
  .novel-plan-directional-crowd {
    .crowd-container {
      background: #f8f8f8;
      padding: 0 20px;
    }
    /deep/ .el-collapse-item__header {
      height: 70px;
      line-height: 70px;
    }
    /deep/ .el-collapse-item__content {
      margin-top: 0px;
    }
    /deep/ .el-collapse-item__wrap {
      overflow: visible;
    }
    /deep/ .el-icon-arrow-right {
      display: none;
    }
    .region {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      > span {
        width: 100px;
      }
    }
    .already-choose-regions {
      width: 400px;
      margin-left: 150px;
      margin-bottom: 8px;
      padding: 8px 12px;
      border-radius: 4px;
      box-shadow: 0 0 0 1px #e3e3e3;

      section {
        > p {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .choose-regions-list {
          padding-left: 8px;
          max-height: 85px;
          overflow: hidden;
          position: relative;
          .region-wrapper {
            .container {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              .region-item {
                margin: 10px 10px 10px 0;
                cursor: pointer;
                i {
                  vertical-align: unset;
                  margin-left: 5px;
                  color: #bdbec0;
                  font-size: 14px;
                }
                &:hover {
                  i {
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
    .choose-region {
      margin-left: 150px;
      position: relative;
      > div:nth-of-type(1) {
        display: inline-block;
      }
      .region-info-list {
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        height: 260px;
        width: 400px;
        overflow: hidden;
        background: #fff;
        z-index: 9999;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 5px 10px 0 rgba(0, 0, 0, 0.04), 0 0 0 1px hsla(0, 0%, 87%, 0.8);
        .region-container {
          .wrapper {
            // overflow: hidden;
          }
        }
      }
    }
    .crowd-limits {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        width: 100px;
      }
    }
    .add-more {
      text-align: center;
      color: #9c9c9c;
      cursor: pointer;
    }
    .crowd-title {
      padding: 20px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-input {
        width: 400px;
        /deep/.el-input__inner {
          background-color: inherit !important;
        }
      }
      .operation {
        span {
          color: #3facdb;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .luoka-directional {
    /deep/ .el-collapse {
      border: none;
      padding-top: 0;
    }
    /deep/ .el-collapse-item__header {
      border: none;
    }
    /deep/ .el-collapse-item__wrap {
      border: none;
    }
    .radio-list {
      .luoka-directional-part {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
        > span {
          width: 100px;
        }
        > div {
          .custom-type {
            width: 257px;
            /deep/ .el-checkbox {
              margin: 10px 10px 10px 0;
            }
            .already-brand-choose {
              margin-bottom: 8px;
              padding: 8px 12px;
              border-radius: 4px;
              box-shadow: 0 0 0 1px #e3e3e3;
              section {
                > p {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
                .brand-list {
                  padding-left: 8px;
                  max-height: 85px;
                  overflow: hidden;
                  .brand-wrapper {
                    .container {
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: flex-start;
                      align-items: center;
                      .phone-item {
                        margin: 10px 10px 10px 0;
                        cursor: pointer;
                        i {
                          vertical-align: unset;
                          margin-left: 5px;
                          color: #87888c;
                          font-size: 14px;
                        }
                        &:hover {
                          i {
                            color: #000;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .choose-brand {
              position: relative;
              .phone-brand-list {
                position: absolute;
                top: 40px;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                height: 260px;
                width: 257px;
                overflow: hidden;
                background: #fff;
                z-index: 9999;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
                  0 5px 10px 0 rgba(0, 0, 0, 0.04),
                  0 0 0 1px hsla(0, 0%, 87%, 0.8);
                .phone-container {
                  .wrapper {
                    > p {
                      padding: 12px 10px;
                    }
                    .ul-phone-list {
                      li {
                        label {
                          box-sizing: border-box;
                          padding: 12px 10px;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          color: #24262e;
                        }
                        &:hover {
                          background: #ebedf0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .market {
            /deep/ .el-checkbox-group {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  .next-step {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
