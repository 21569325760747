<template>
  <div class="add-novel-plan">
    <div class="add-novel-plan-container">
      <div class="nav-point box-shadow-light-grey border-radius-6" v-show="curStep !== 3">
        <div class="novel-put-setting setting">
          <p>投放时间</p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('novel-plan-put-time')"
            >
              投放时间</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('novel-plan-bidding-method')"
              >出价方式</a
            >
          </p>
        </div>
        <div class="novel-add-setting setting">
          <p>广告设置</p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('novel-plan-page')"
              >选择推广页</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('novel-plan-setting-top')"
              >设置顶部图</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('novel-plan-ad-setting')"
              >外层广告设置</a
            >
          </p>
        </div>
        <div class="novel-directional-setting setting">
          <p>基础信息</p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('novel-plan-directional-crowd')"
              >定向人群</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget('luoka-directional')"
              >罗卡定向</a
            >
          </p>
        </div>
        <div class="novel-build-settings setting">
          <p>生成设置</p>
          <p>计划详情</p>
        </div>
      </div>
      <step-one
        :curStep.sync="curStep"
        ref="stepOne"
        v-show="curStep === 1"
        :checkList="checkList"
        @goBack="$emit('goBack')"
      ></step-one>
      <step-two
        style="flex-grow:1"
        :checkList="checkList"
        :curStep.sync="curStep"
        v-show="curStep === 2"
      ></step-two>
      <step-three
        style="width:100%"
        :checkList="checkList"
        v-if="curStep === 3"
        :curStep.sync="curStep"
        @closeDrawer="$emit('closeDrawer')"
      >
      </step-three>
    </div>
  </div>
</template>

<script>
import stepOne from './step-one.vue'
import stepTwo from './step-two.vue'
import stepThree from './step-three.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      curStep: 1,
      checkList: [],
      posType: 999,
      typeStyle: {
        cursor: 'pointer'
      }
    }
  },
  computed: {
    ...mapState(['echoNovelPlanInfo', 'isProjectSaved'])
  },
  watch: {
    curStep: {
      handler (newV) {
        if (newV !== 1) {
          this.typeStyle = {
            cursor: 'default'
          }
        } else {
          this.typeStyle = {
            cursor: 'pointer'
          }
        }
      }
    },
    posType: {
      handler (newV) {
        if (newV === 999) {
          this.checkList = ['朋友圈信息流']
        }
        if (newV === 101) {
          this.checkList = ['公众平台流量']
        }
        if (newV === 8) {
          this.checkList = ['激励模式']
        }
      }
    }
  },
  created () {
    const posType = this.echoNovelPlanInfo.posType
    switch (posType) {
      case 999:
        this.checkList = ['朋友圈信息流']
        this.posType = 999
        break
      case 101:
        this.checkList = ['公众平台流量']
        this.posType = 101
        break
      case 8:
        this.checkList = ['激励模式']
        this.posType = 8
        break
    }
  },
  // beforeRouteLeave (to, from, next) {
  //   if (!this.isProjectSaved) {
  //     ;
  //     ;
  //     setTimeout(() => {
  //       this.$confirm('确定要离开?系统可能不会保存您所做的操作！', '提示', {
  //         confirmButtonText: '确定',
  //         cancelButtonText: '取消',
  //         type: 'warning'
  //       })
  //         .then(() => {
  //           next()
  //         }).catch(() => {})
  //     }, 10)
  //   }
  // },
  methods: {
    ...mapMutations(['setEchoNovelPlanInfo']),
    initIntersectionObserver () {
      const observer = new IntersectionObserver((entries) => {
      })
      observer.observe(document.querySelector('#novel-plan-put-time'))
    },
    handleChange (value) {
      value.length > 1 && value.shift()
    },
    // 跳转到制定位置
    scrollTarget (id) {
      document.querySelector(`#${id}`).scrollIntoView({
        behavior: 'smooth'
      })
    },
    chooseType (type) {
      if (this.curStep !== 1) {
        return
      }
      if (this.posType !== type) {
        this.$confirm(
          '是否确认修改？修改投放计划，已创建的投放计划内容将清空。',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          // this.setEchoNovelPlanInfo({})
          this.$refs.stepOne.resetData()
          this.posType = type
        })
      }
    }
  },
  components: {
    stepOne,
    stepTwo,
    stepThree
  }
}
</script>
<style lang="scss" scoped>
a {
  outline: none;
  text-decoration: none;
  color: #000;
}
.change-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 20px;
  // margin:10px 10px 20px
}
.add-novel-plan {
  font-size: 14px;
  // width:calc(100% - 200px);
  .add-novel-plan-header {
    background: #fff;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:nth-of-type(1) {
      padding-left: 20px;
      padding-right: 20px;
      border-right: 1px solid #ccc;
    }
    > div:nth-of-type(2) {
      margin-left: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .choose-type {
        display: flex;
        min-height: 84px;
        .type {
          position: relative;
          margin: 10px 10px 10px 0;
          padding: 0 10px;
          max-width: 450px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 1px solid #ccc;
          border-radius: 4px;
          // cursor: pointer;
          color: #1f1f1f;
          i {
            position: absolute;
            top: 8%;
            left: 95%;
            font-size: 14px;
            color: #b2b2b2;
            display: none;
          }
          .hover-class {
            display: block;
            color: #1C9399;
          }
          &:hover {
            i {
              display: block;
            }
          }
        }
        .selected-active {
          color: #1C9399 !important;
          border: 1px solid #1C9399;
          background: #fafefc;
        }
      }
    }
  }
  .add-novel-plan-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // margin: 10px 0 10px 10px;
    .nav-point {
      width: 150px;
      min-width: 150px;
      background: #fff;
      position: sticky;
      top: 0;
      .setting {
        padding: 20px;
        border-bottom: 1px solid #f5f5f5;
        p {
          margin-bottom: 15px;
          color: #5a5a5a;
          //   text-align: center;
        }
        >p:nth-of-type(1){
          font-weight: bold;
        }
        > p:not(:nth-of-type(1)) {
          font-size: 14px;
          margin-left: 20px;
          color: #000;
        }
      }
    }
  }
}
</style>
